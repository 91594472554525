#checkout{
    .body{
        display: grid;
        grid-template-columns: 50% 50%;
        width: 100%;
        height: 100%;
        
        .grid-item{
            &:nth-child(2){
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: flex-start;
                height: auto;
            }
        }
    }


    .card{
        background-color: $white;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        width: 90%;
        min-height: 20vh;
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        .content{
            display: flex;
            flex-direction: row;
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: center;
            margin-top: 10px;

            .price{
                display: flex;
                flex-direction: row;
                gap: 10px;
                font-weight: 600;
                font-size: 1.6rem;
                align-items: baseline;
    
                .per_month{
                    font-size: 0.9rem;
                    color: $secondary;
    
                    &[data-active="false"]{
                        display: none;
                    }
                }
            }
        }

        .button_wrap{
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: flex-end;
            margin-top: 15px;
            padding-top: 15px;
            border-top: 1px solid rgba(0, 0, 0, 0.2);
        }

    }

    .checkout_form{
        display: flex;
        flex-direction: column;
        gap: 15px;
        max-width: 500px;

        .button{
            width: 150px;
        }

        .checkout_form_label{
            display: flex;
            flex-direction: column;
            gap: 7px;

            .title{
                .optional{
                    font-size: 0.8rem;
                    color: $secondary;
                    font-weight: 500;
                }
            }

            .input{
                max-width: 500px;
                padding: 10px;
            }
        }
    }
}

@media screen and (max-width: 960px) {
    #checkout{
        .body{
            display: flex;
            flex-direction: column-reverse;
            gap: 40px;
        }

        .card{
            width: 100%;
        }
    }
}