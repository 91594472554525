.otherChartsProperties {
    .color_section {
        margin: 10px 0;


        .variable_List {
            display: flex;
            flex-direction: column;
            margin-bottom: 70px;

            .varibale_wrap {
                display: grid;
                grid-template-columns: 55% 35% 10%;
                flex-direction: row;
                width: 100%;
                border-radius: 5px;
                padding: 10px 20px 10px 30px;
                margin-bottom: 5px;
                box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
                align-items: center;

                .vw_color {
                    width: 60px;
                    height: 20px;
                    border-radius: 5px;
                }

                .vw_icon {
                    color: $red;
                    cursor: pointer;
                }
            }
        }

        .title {
            font-weight: 500;
            margin-bottom: 20px;
            margin-top: 0px;

            .title_desc {
                color: rgba(0, 0, 0, 0.5);
                font-size: 0.8rem;
            }
        }

        .button-wrap {
            margin-top: 30px;
        }

        .inputs-wrap {
            display: flex;
            flex-direction: column;
            gap: 40px;
        }

        .input_wrap {
            display: flex;
            flex-direction: row;
            gap: 40px;
        }

        .label_inputs_wrap {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .input_wrap {
                align-items: center;

                .title {
                    margin-bottom: 0;
                }

                .content {
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    width: 100%;
                }

                .input {
                    padding: 10px;
                }

                .colour_pick_wrap {
                    width: 100%;
                }
            }
        }

        .radiobtn_wrap {
            display: flex;
            flex-direction: column;
            grid-template-columns: auto auto auto auto;
            row-gap: 20px;
        }

        .colour_pick_wrap {
            display: grid;
            grid-template-columns: auto auto auto auto auto;
            row-gap: 20px;
            margin-bottom: 70px;

            &.no-bottom-margin {
                margin-bottom: 0;
            }

            .color_radiobtn {
                width: 40px;
                height: 40px;
                border-radius: 5px;
                cursor: pointer;
                box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
                transition: all 0.3s ease;

                &:hover {
                    transform: scale(1.05);
                }

                &:active {
                    transform: scale(0.97);
                }

                .lcp_cp_label,
                .bcp_cp_label {
                    display: block;
                    width: 100%;
                    height: 100%;
                    border-radius: 5px;
                    border: 2px solid transparent;
                    cursor: pointer;
                    transition: all 0.3s ease;

                    &[data-active="true"] {
                        border: 2px solid $theme;
                    }
                }

                .lcp_cp_radiobtn {
                    display: none;

                    &:checked~label {
                        border: 2px solid $theme;
                    }
                }

                .bcp_cp_radiobtn {
                    display: none;
                }
            }
        }

        .inputs_wrap {
            display: flex;
            flex-direction: row;
            gap: 20px;
            width: 100%;

            .input_wrap {
                display: flex;
                flex-direction: column;
                gap: 5px;
                width: 100%;

                .title {
                    margin-bottom: 0;
                }
            }
        }

    }
}