#knobSlide{
    display: flex;
    flex-direction: column;
    gap: 20px;

    .input-wrap{
        display: flex;
        flex-direction: column;
        gap: 5px;

        .uppercase{
            text-transform: uppercase;
        }

        .values-wrap{
            display: flex;
            flex-direction: column;
            gap: 10px;

            .value-wrap{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;
                padding-bottom: 10px;
                border-bottom: 2px solid rgba(0, 0, 0, 0.2);
                
                &:last-child{
                    border-bottom: 0px;
                }
                
                .knob-inputs-wrap{
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    width: 100%;
                }

                .icons-wrap{
                    display: flex;
                    flex-direction: row;
                    gap: 20px;
                }
            }
        }
    }

    .btn_group{
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        border-radius: 7px;
        overflow: hidden;

        .button_grp_set{
            width: 100%;
            border: none;
            background-color:#e8e8e8;
            color: black;
            padding: 5px 0;
            
            
        }

        

        .left_btn{
            border-right: 2px solid white;
        }
        .left_btn:hover{
            cursor: pointer;
        }

        .left_btn:active{
            background-color: rgba(0, 0, 0, 0.2);
        }

        .right_btn{
            border: none;
        }

        .right_btn:hover{
            cursor: pointer;
        }

        .right_btn:active{
            background-color: rgba(0, 0, 0, 0.2);
        }
    }
}

@media screen and (max-width: 770px) {
    #knobSlide{
        .input-wrap{
            .values-wrap{
                .value-wrap{
                    .knob-inputs-wrap{
                        flex-direction: column;
                    }
                }
            }
        }
    }
}