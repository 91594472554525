 #whiteLabel {
    position: relative;
    // padding-right: 200px;
    
  
    .whiteLabel_form {
      background-color: $white;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
      margin: 30px 0 30px 0;
      padding: 40px;
      border-radius: 5px;
  
      &[data-active="false"] {
        display: none;
      }

      .whiteLabel_input_wrapper_bottom{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
      }
  
      .whiteLabel_input_wrapper {
        margin-top: 25px;
  
        .whiteLabel_input_title2 {
          font-weight: bold;
          margin-bottom: 7px;
          padding-right: 20px;
        }
  
        .whiteLabel_input_title {
          margin-bottom: 3px;
          font-weight: bold;
  
        }
  
        .whiteLabel_input_title-nav {
          background-color: $white;
          box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
          border-radius: 5px;
          padding: 10px;
          width: 100%;
        }
  
        .whiteLabel_input_desc {
          margin-bottom: 10px;
        }
      }
  
      .whiteLabel_bottom {
        .button {
          width: 200px;
        }
      }
    }
  
    .site_logo_double_wrap {
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 30px;
      margin-top: 25px;
  
      .whiteLabel_logo_change_wrap {
        background-color: $white;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
        padding: 40px;
        border-radius: 5px;
        width: 100%;
  
        .nav-bar-color {
          margin-bottom: 20px;
        }
  
        .whiteLabel_input_title {
          font-weight: 500;
        }
  
        .colour_pick_wrap {
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          gap: 5px;
          margin-top: 10px;
  
          &.no-bottom-margin {
            margin-bottom: 0;
          }
  
          .hex-input {
            text-align: center;
            height: 30px;
            width: 70px;
            padding: 5px;
            border-radius: 5px;
          }
  
          .color_radiobtn {
            width: 30px;
            height: 30px;
            border-radius: 5px;
            cursor: pointer;
            box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
            transition: all 0.3s ease;
  
            &:hover {
              transform: scale(1.05);
            }
  
            &:active {
              transform: scale(0.97);
            }
  
            .lcp_cp_label,
            .bcp_cp_label {
              display: block;
              width: 100%;
              height: 100%;
              border-radius: 5px;
              border: 2px solid transparent;
              cursor: pointer;
              transition: all 0.3s ease;
  
              &[data-active="true"] {
                border: 2px solid gray;
              }
            }
  
            .lcp_cp_radiobtn {
              display: none;
  
              &:checked~label {
                border: 2px solid $theme;
              }
            }
  
            .bcp_cp_radiobtn {
              display: none;
            }
          }
        }
  
        .input-label-wrap {
          display: flex;
          gap: 5px;
        }
  
        .whiteLabel_logo_image_change {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 40px;
  
          #whiteLabel_logo_input,
          #whiteLabel_icon_logo_input {
            display: none;
          }
  
          .whiteLabel_input_title {
            font-weight: 500;
            text-transform: capitalize;
          }
  
          .whiteLabel_site_input_wrap {
            display: flex;
            flex-direction: column;
            gap: 10px;
          }
        }
  
      }
  
  
  
    }
  
    .whiteLabel_site_settings_wrap {
      .site_double_wrap {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
      }
    }
  
    .whiteLabel_domain {
  
      .whiteLabel_domain_title {
        margin-bottom: 25px;
      }
  
      .site_double_wrap {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
  
        .site_domain_double_wrap {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          background-color: rgb(207, 207, 207);
          border-radius: 5px;
          width: 100%;
  
          .site_domain_text {
            margin-left: 10px;
            margin-right: 10px;
          }
        }
      }
  
      .note {
        margin-top: 5px;
        font-size: 0.8rem;
        color: $secondary;
      }
  
      .bottom_button_wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
  
        .button {
          margin-top: 20px;
        }
      }
  
    }
  
    .card {
      background-color: $white;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
      border-radius: 5px;
      padding: 30px;
      margin:0px 0px 30px 0px;
  
      &[data-active="false"] {
        display: none !important;
      }
  
      .title {
        margin-bottom: 20px;
      }
  
      .delete_section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
  
        .section {
          display: flex;
          flex-direction: column;
          justify-content: center;
  
          &:nth-child(1) {
            .subtitle {
              font-weight: 500;
            }
  
            .subtitle_desc {
              margin-top: 5px;
              color: rgba(0, 0, 0, 0.6);
              font-size: 0.8rem;
            }
          }
  
          &:nth-child(2) {
            .button {
              border: 2px solid $red;
              color: $red;
              padding-left: 20px;
              padding-right: 20px;
              background-color: rgba(220, 53, 69, 0.2);
            }
          }
        }
      }
  
      .bottom {
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
  
      .input_wrap {
        margin-bottom: 20px;
  
        .title {
          margin-bottom: 10px;
          font-weight: 500;
        }
  
        .input {
          padding: 10px 15px 10px 15px;
        }
  
        .input_with_icon_wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          background-color: rgba(0, 0, 0, 0.2);
          border-radius: 5px;
  
          .input_icon {
            width: 40px;
            font-size: 1.2rem;
          }
  
          .input {
            padding: 10px 15px 10px 15px;
          }
        }
      }
  
      .sub_row {
        background-color: rgba(0, 0, 0, 0.1);
  
        .sub_row_icon {
          margin-right: 10px;
          font-size: 1.2rem;
        }
      }
  
      .button_wrap {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-bottom: 20px;
  
        .button {
          gap: 7px;
        }
      }
    }
  
    .templatetags {
      padding: 0;
      padding: 30px 0 30px 0;
  
      .title,
      .button-wrap {
        padding: 0 30px 0 30px;
      }
  
      .content {
        display: flex;
        flex-direction: column;
        gap: 15px;
  
        .meta-tag {
          display: flex;
          flex-direction: row;
          gap: 10px;
          align-items: flex-start;
          padding: 10px 30px 10px 30px;
  
          &:nth-child(even) {
            background-color: $light1;
          }
  
          .tag-button-wrap {
            display: flex;
            flex-direction: row;
            gap: 10px;
  
            .button {
              font-size: 1.1rem;
  
              &.delete {
                background-color: $red;
              }
            }
          }
  
          .input-wrap {
            width: 100%;
            display: flex;
            flex-direction: row;
            gap: 10px;
          }
        }
      }
    }
  
  }


@media screen and (max-width: 1036px) {
  #whiteLabel {
    .white_label_pricing {
      height: 100vh;
    }

    .whiteLabel_form {
      padding: 40px 20px 40px 20px;
    }

    .site_logo_double_wrap {
      flex-direction: column;

      .whiteLabel_logo_change_wrap {
        padding: 20px;

        .whiteLabel_logo_image_change {
          gap: 20px;
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }

    .whiteLabel_site_settings_wrap {
      .site_double_wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .templatetags {
      .content {
        .meta-tag {
          .tag-button-wrap {
            flex-direction: column;
          }

          .input-wrap {
            flex-direction: column;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 770px) {
  #whiteLabel {
    .card {
      .delete_section {
        display: flex;
        flex-direction: column;
        gap: 15px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  #whiteLabel {
    padding-right: 20px;

    .card{

    }
  }


}