:root {
    --background-color: ;
    --text-color: ;
    --forgotPwd: ;
    --loginBtn: ;
    --inputBorderBottom: ;
    --labelTop: ;
    --labelBottom: ;
    --labelRight: ;
    --labelLeft: ;
  }
$loginTheme: var(--background-color);
$loginFontColor: var(--text-color);
$forgotPassword: var(--forgotPwd);
$loginButton: var(--loginBtn);
$inputBorderBottom: var(--inputBorderBottom);
$labelTop: var(--labelTop);
$labelBottom: var(--labelBottom);
$labelRight: var(--labelRight);
$labelLeft: var(--labelLeft);
$theme:  #050000;
$fontColor:  #fff;
$black:  #000000;
$white:  #ffffff;
$themebk:  #6200EE;
$light1:  #EBEBEB;
$light2:  #FAFAFA;
$light3:  #f5f5f5;
$blue:  #007bff;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:  #e83e8c;
$red:  #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:  #28a745;
$teal:  #20c997;
$cyan:  #17a2b8;
$white:  #fff;
$gray:  #6c757d;
$gray-dark:  #343a40;
$primary:  #007bff;
$secondary:  #6c757d;
$success:  #28a745;
$info:  #17a2b8;
$warning:  #ffc107;
$danger:  #dc3545;
$light:  #f8f9fa;
$dark:  #424242;
$dark-1:  #444941;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0');

@import './animations';

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html, body{
    font-family: Arial, Helvetica, sans-serif;
    font-smooth: antialiased;
    -webkit-font-smoothing: antialiased;
    // color: $dark-1;
    // scroll-behavior: smooth;
    overflow-x: clip;
    overflow-y: auto;
}


.serviceNotAvailable{
    display: flex;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 9999;
    align-items: center;
    justify-content: center;
}

.container{
    transition: all 0.5s ease;
    background: $white;
    color: $dark;
    width: 100%;
    // min-height: 90vh;
    margin-top: 0rem;
    padding: 20px 20px 20px 20px;
    @include fade-in-animation(0.3s);

    .top{
        display: flex;
        flex-direction: row;
        margin-bottom: 30px;

        .title{
            font-size: 2.25rem;
        }
    }

    &[data-active="true"]{
        opacity: 1;
    }

    &[data-active="false"]{
        display: none;
        opacity: 0;
    }
}

.loader{
    display: flex;
    align-items: center;
    justify-content: center;
}

.input{
    outline: none;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.25rem;
    width: 100%;
    transition: all 0.3s ease;
    padding: 10px;

    &:focus{
        border: 2px solid $theme;
    }

    &[data-valid="false"]{
        border: 1px solid $red
    }
}

.warning_msg{
    color: $red;
    font-size: 0.8rem;
    font-weight: 400;
    margin-top: 7px;

    &[data-active="true"]{
        display: block;
    }

    &[data-active="false"]{
        display: none;
    }
}

.button{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px 10px 15px;
    width: max-content;
    color: $white;
    background-color: $theme;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    border: 0;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
    outline: none;

    &[data-active="false"]{
        pointer-events: none;
        background-color: rgba(131, 131, 131, 0.24);
        border-color: rgba(131, 131, 131, 0.24);
        color: rgba(131, 131, 131, 0.48);
    }

    &:active{
        transform: scale(0.97);
    }
}

.note{
    font-size: 0.9rem;
    color: $secondary;
}

.chip-tag{
    max-width: max-content;
    width: 100%;
}

.color-wrap{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

/* @media screen and (min-width: 1024px) {
    ::-webkit-scrollbar{
        width: 12px;
        background-color: $light1;
    }
    
    ::-webkit-scrollbar-thumb{
        width: 100%;
        background-color: rgba(131, 131, 131, 0.4);
        border-radius: 5px;
    }
} */

@media screen and (max-width: 770px) {
    .container{
        padding: 20px;
    }
}

@media screen and (min-width: 1023px) and (max-width: 1080px) {
    .container{
        height: 95vh;
        padding: 30px 30px 30px 30px;
    }
}

@import './components/components';

@import './pages/pages';