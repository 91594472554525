.switch-widget{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // gap: 20px;

    .toggle-switch-wrap{
        transition: all 0.4s ease;
    }
}