#calendarEvent{
    display: flex;
    flex-direction: column;
    gap: 20px;

    .top{
        .title{
            width: 100%;
        }
    }

    .double-wrap{
        display: flex;
        flex-direction: row;
        gap: 100px;
    }

    .input-wrap{
        display: flex;
        flex-direction: column;
        gap: 5px;

        .event-textarea{
            min-width: 100%;
            max-width: 38vw;
        }
        
        .note{
            font-size: 0.8rem;
            color: $secondary;
        }

        .reminders-content{
            display: flex;
            flex-flow: row wrap;
            gap: 10px;
        }
    }

    .calendar-checkbox{
        width: max-content;
    }

    .button-wrap{
        display: flex;
        gap: 10px;
        justify-content: flex-end;

        .delete{
            &[data-active="true"] {
                background-color: $red;
            }
        }
    }
}

@media screen and (max-width: 770px) {
    #calendarEvent{
        .double-wrap{
            flex-direction: column;
            justify-content: flex-start;
        }

        .input-wrap{
            .event-textarea{
                max-width: 100%;
            }

            .reminders-content{
                flex-direction: column;
            }
        }
    }   
}