.filterdIconsPicker{
    display: flex;
    flex-direction: row;
    gap: 20px;
    row-gap: 20px;
    flex-flow: row wrap;
    justify-content: space-evenly;


    .fip_icons_label{
        width: 45px;
        height: 45px;
        display: flex;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        font-size: 2rem;
        background-color: $white;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
        cursor: pointer;
        transition: all 0.2s ease;
        border: 2px solid transparent;

        &:hover{
            transform: scale(1.05);
        }

        &:active{
            transform: scale(0.98);
        }
    }

    .fip_radioButton{
        display: none;
        
        &:checked ~ label{
            border-color: $theme;
            background-color: rgba(98, 0, 238, 0.2);
        }
    }

}