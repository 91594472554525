.empty-dashboard{
    display: none;
    flex-direction: column;
    gap: 50px;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 65vh;
    padding: 20px;
    text-align: center;
    text-transform: capitalize;
    @include fade-in-animation(0.3s);

    &[data-active="true"] {
        display: flex;
        padding-left: 0px;
    }

    .empty_dashboard_img{
        width: 350px;  
    }

    .is-mobile{
        width: 264px;
    }
}

@media screen and (max-width: 770px) {
    .empty-dashboard{
    
        .empty_dashboard_img{
            max-width: 200px;
        }
    }
}