.global-widget-slider{
    position: relative;

    .top{
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    #global-widget-slider-body{
        opacity: 1;
        transition: all 0.3s ease;
        
        &[data-active="false"]{
            pointer-events: none;
            opacity: 0.5;
        }
    }
}

@import './deviceSelect';
@import './geoLocation';