#addReport, #editReport{
    
    .input{
        padding: 10px;
    }

    .input_wrap{
        margin-bottom: 20px;

        .note{
            margin-top: 5px;
            color: rgba(0, 0, 0, 0.6);
            font-size: 0.8rem;
        }

        .title{
            font-weight: 500;
            margin-bottom: 10px;
        }

        .input_schedule_wrap{
            display: flex;
            flex-direction: row;
            flex-flow: row wrap;
            align-items: center;
            gap: 10px;
        }

        .input_emails_wrap{
            display: flex;
            flex-direction: row;
            gap: 15px;
            flex-flow: row wrap;
            align-items: center;

            .input_email_wrap{
                display: flex;
                flex-direction: row;
                width: 100%;
                gap: 7px;

                .input{
                    max-width: 270px;
                }

                .button{
                    padding: 0 7px 0 7px;
                }

                .add_button{
                    font-size: 1.4rem;
                }
            }
        }
    }

    .simple_cvs_body{
        background-color: $white;
        border-radius: 5px;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
        margin-top: 30px;

        .top_wrap{
            margin-bottom: 25px;
            background-color: $light;
            padding: 15px;

            .note{
                margin-top: 5px;
                color: rgba(0, 0, 0, 0.6);
                font-size: 0.8rem;
            }
        }


        .body{
            display: flex;
            flex-direction: column;
            padding: 0 15px 15px 15px;
            
            .row_wrap{
                display: flex;
                flex-direction: row;
                flex-flow: row wrap;
                align-items: center;
                gap: 15px;
    
                .title{
                    margin-bottom: 0;
                }
            }

            .input_wrap{
                .note{
                    margin-top: 5px;
                    color: rgba(0, 0, 0, 0.6);
                    font-size: 0.8rem;
                }

                .report_name_input{
                    max-width: 350px;
                }
            }
        }

    }

    .buttons_wrap{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 15px;
        align-items: center;
        margin-top: 40px;

        .cancel{
            background-color: $red;
        }
    }

    .save_button_wrap{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin: 0;
    }
}