.imagePopUp{
    position: relative;
    z-index: 99;
    
    .backDrop_content{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 40px;
        padding-top: 7rem;
        padding-right: 20px;
        padding-bottom: 20px;
        padding-left: 300px;
        width: 100%;

        .top-wrap{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 40px;
            width: 100%;
            color: $white;
            
            .icon{
                cursor: pointer;
                font-size: 1.5rem;
            }

            .popup-image-download{
                text-decoration: none;
                color: $white;
            }
        }

        .popup-image{
            width: 100%;
            max-width: 700px;
        }
    }
}

@media screen and (max-width: 770px) {
    .imagePopUp{
        .backDrop_content{
            padding-left: 20px;
        }
    }
}

@media screen and (min-width: 1024px) and (max-width: 1080px) {
    .imagePopUp{
        .backDrop_content{
            padding-left: 300px;
        }
    }
}