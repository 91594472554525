#addMqttServer{
    .content{
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .double_wrap{
        display: flex;
        flex-direction: row;
        gap: 20px;
        width: 100%;

        &[data-active="false"]{
            display: none;
        }
        
        .limit-width{
            max-width: 150px;
        }

        .input_wrap{
            width: 100%;

            .title{
                font-weight: 500;
                margin-bottom: 7px;
            }
        }
    }
    
    .button_wrap{
        display: flex;
        justify-content: flex-end;
    }

    .ssl_self_signed_wrap{
        display: flex;
        flex-direction: column;
        gap: 10px;

        &[data-active="false"]{
            display: none;
        }

        .input_wrap{
            display: flex;
            flex-direction: column;
            gap: 5px;

            .required{
                color: $red;
            }

            .title{
                font-size: 0.9rem;
                font-weight: 500;
            }

            .double_wrap{
                .hide{
                    display: none;
                }

                .readOnly{
                    cursor: default;
                }
            }
        }
    }

}

@media screen and (max-width: 770px) {
    #addMqttServer{
        .double_wrap{
            flex-direction: column;
        }

        .ssl_self_signed_wrap{
            .input_wrap{
                .double_wrap{
                    flex-direction: row;
                }
            }
        }
    }
}