#addNotification, #editNotification{
    display: contents;
    .input_right{
        text-align:right;
    }

    .input_wrap{
        display: flex;
        flex-direction: column;
        gap: 7px;
        margin-bottom: 5px;
        margin-top: 20px;

        .title{
            font-weight: 500;
        }

        .input{
            padding: 10px;
        }
    }

    .condition_section{
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        
        .add_condition_button{
            width: 50px;
        }

        .condition_wrap{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;

            .close_icon{
                font-weight: 700;
                font-size: 1.4rem;
                color: $red;
                cursor: pointer;
            }
        }

        .notification_statement{
            display: flex;
            flex-direction: row;
            gap: 15px;
            align-items: center;
            flex-flow: row wrap;

            .input{
                width: 100px;
                padding: 5px;
            }
        }
    }

    .trigger_wrap{
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 20px;

        .bottom_text{
            display: flex;
            flex-direction: row;
            flex-flow: row wrap;
            align-items: center;
            gap: 7px;
            font-weight: 600;
            font-size: 0.9rem;
    
            .input{
                width: 100px;
                padding: 5px;
            }

        }

        .check_box_wrap{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
            font-weight: 600;
            font-size: 0.9rem;

            input, label{
                cursor: pointer;
            }
        }

        .warn_box{
            padding: 10px;
            border: 2px solid rgba(255, 204, 0, 1);
            background-color: rgba(255, 204, 0, 0.2);
            color: rgb(144, 116, 4);
            font-size: 0.9rem;
        }
    }


    .button_wrap{
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        gap: 15px;

        .cancel{
            background-color: $red;
        }
    }
}