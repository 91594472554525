.back_icon_wrap{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .back_icon{
        display: flex;
        flex-direction: row;
        width: max-content;
        align-items: center;
        gap: 3px;
        cursor: pointer;
        font-size: 1.2rem;
        
        &:hover{
            animation: back-forward 1s ease-in-out infinite;
        }

        .back_icon_svg_wrap{
            display: flex;
            align-items: center;
            border-radius: 50%;
            transition: all 0.3s ease;
        }
    }
}

@keyframes back-forward {
    0%{
        gap: 3px;
    }
    50%{
        gap: 7px;
    }
    100%{
        gap: 3px;
    }
}