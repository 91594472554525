.activateSim{
    display: flex;
    flex-direction: column;
    gap: 20px;

    .input-wrap{
        display: flex;
        flex-direction: column;
        gap: 5px;
        
        .title{
            font-weight: 500;
        }
    }
}