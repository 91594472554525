#chartType,#NavigatorType,#controllType{
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    gap: 30px;
    width: 100%;

    .ct_item_wrap{
        display: flex;
        flex-direction: column;
        align-items: center;

        .ct_label_name{
            margin-top: 10px;
            font-size: 0.9rem;
        }

        .ct_label{
            width: 70px;
            height: 70px;
            background-color: $white;
            box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
            border: 1px solid transparent;
            border-radius: 5px;
            cursor: pointer;
            transition: all 0.3s ease;
            padding: 10px;

            &:hover{
                transform: scale(1.02);
            }

            .ct_label_icon{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                font-size: 4rem;
            }
        }
    
        .ct_radio_btn{
            display: none;
    
            &:checked ~ label {
                background-color: rgba(98, 0, 238, 0.3);
                border: 1px solid rgba(98, 0, 238, 1);
            }
        }
    }
}