.verticle_bar {
  min-width: 100px;
  min-height: 300px;
  height: auto;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #b5b5b7;
  border-top: 1px solid #b5b5b7;

  .main_containter {
    position: relative;
    width: 30px;
    height: auto;
    background-color: #e0e0e0;
    border: 1px solid #b5b5b7;

    .arrow_head {
      position: absolute;
      left: -62%;
      width: 30px;
      height: 30px;
      background-color: #475ca7;
      clip-path: polygon(100% 51%, 0 0, 0 94%);
    }

    .inside_colors {
      width: 100%;
      height: 100%;

      .color_dangerous {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .color_dangerous_container {
          width: 100%;
          height: 30%;
          background-color: #d4d4d4;
        }
      }
      .color_medium {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .color_medium_container {
          width: 100%;
          height: 13%;
          background-color: #bfbfc0;
        }
      }
    }
  }

/* Added styles for measurement lines */

.inside_colors::after {
    content: "";
    position: absolute;
    top: 0;
    right: -0px;
    bottom: 0;
    width: 2px;
    background-color: black;
  }
  
  .measurement-line {
    position: absolute;
    top: 2px;
    left: 88px;
    right: -10px; 
    width: 10px;
    height: 1px;
    background-color: black;
  }
  
  .measurement-line:nth-child(5n) {
    height: 5px; 
  }
}
