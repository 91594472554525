.liveStream_widget{
    .iframe-wrapper{
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 100%;

        .iframe-wrapper-top{
            display: flex;
            justify-content: flex-end;
            width: 100%;
        }
    }

    .button-wrap{
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        align-items: center;
        
        .note{
            color: $secondary;
        }
    }

    .button{
        width: max-content;
        height: max-content;
    }
}