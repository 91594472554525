.packages{
    .packagesTabPanel{
        min-height: 300px;
        padding-top: 40px;

        &[data-height="fixed"]{
            overflow-y: auto;
            height: 300px;
        }
    }

    .switch-wrap{
        margin: 0 0 25px 0;
    }

   .packages_body{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 20px;

       .package{
            background-color: $white;
            border-radius: 5px;
            box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 20px;
            min-width: 160px;
            min-height: 260px;

            &[data-disable="true"]{
                pointer-events: none;
                opacity: 0.4;
            }

            .title{
                text-transform: capitalize;
                font-weight: 600;
            }

            .desc, .price{
                align-self: center;
            }

            .desc{
                display: flex;
                flex-direction: column;
                gap: 7px;
                text-align: center;
                font-size: 0.8rem;
            }

            .price{
                margin-top: 10px;
                margin-bottom: 10px;

                span{
                    font-size: 0.8rem;
                    font-weight: 600;
                    color: $secondary;
                }
            }
       }
   }

   .sub_body{
       display: flex;
       flex-direction: column;
       gap: 10px;

       .image_wrap{
           display: flex;
           flex-direction: column;
           justify-content: center;
           align-items: center;
           
           .image{
               width: 260px;
           }

           .text{
               text-transform: capitalize;
           }

       }

       .sub_item{
           padding: 15px 20px 10px 20px;
           display: flex;
           width: 100%;
           justify-content: space-between;
           align-items: center;
           border-top: 1px solid rgba(0, 0, 0, 0.2);

           &:nth-child(1){
               padding-top: 0;
               border-top: 1px solid transparent;
           }

           .wrap{
               display: flex;
               align-items: center;
               gap: 15px;

               .price{
                   .price-month{
                       font-size: 0.8rem;
                       font-weight: 500;
                       color: $secondary;
                   }
               }
           }
       }
   }
}

@media screen and (max-width: 1036px) {
    .packages{
        .packagesTabPanel{
            &[data-height="fixed"]{
                min-height: 850px;
            }
        }

        .packages_body{
            flex-direction: column;
        }
    }
}