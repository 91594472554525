.left_nav_bar::-webkit-scrollbar{
  display: none;
}

.left_nav_bar{
    scrollbar-width: none;
}

.RightNavBAr::-webkit-scrollbar{
  display: none;
}

.RightNavBAr {
  overflow: "scroll";
  scrollbar-width: none;
}