#cellular{
    .top{
        display: flex;
        flex-direction: column;
    }
    
    .top-content{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        
        .input-wrap{
            display: flex;
            align-items: center;
            gap: 10px;
            max-width: 350px;
            width: 100%;
        }

        .button_wrap{
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            gap: 20px;
    
            .button{
                width: max-content;
                padding-top: 10px;
                padding-bottom: 10px;
                gap: 10px;
            }
        }
    }

    .cellular-connection-wrap{
        display: flex;
        align-items: center;
        padding: 3px;
        gap: 10px;
        
        .icon{
            display: flex;
            align-items: center;
            font-size: 1rem;

            &[data-color="grey"] {color: $secondary}
            &[data-color="yellow"] {color: $yellow}
            &[data-color="blue"] {color: $blue}
            &[data-color="green"] {color: $green}
            &[data-color="orange"] {color: $orange}
            &[data-color="red"] {color: $red}
        }
    }

    .body{
        margin-top: 20px;

        .device_row{
            &:hover{
                //transition: all 0.5s ease;
                background-color: #eeee;
            }
        }
    }
}