.delete_propup{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;

    .dp_button_wrap{
        display: flex;
        flex-direction: row;
        gap: 20px;
        justify-content: flex-end;
    }
}