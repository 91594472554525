.publicLink{
    display: flex;
    flex-direction: column;
    gap: 20px;

    .button-wrap{
        display: flex;
        flex-direction: row;
        gap: 20px;
        justify-content: flex-end;

        .delete{
            &[data-active="true"] {
                background: $red;
            }
        }
    }

    .main-content{
        display: flex;
        gap: 30px;
    }

    .qr_wrapper{
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        align-self: center;
        max-width: 210px;
        width: 100%;

        .empty-message{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 15px;
            border: 2px solid rgba(0, 0, 0, 0.2);
            border-radius: 5px;
            min-height: 210px;
            width: 100%;
            text-align: center;
        }
    }
    
    .content{
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;

        .input_wrap{
            display: flex;
            flex-direction: column;
            gap: 5px;

            .input-button-wrap{
                display: flex;
                background-color: $light1;
                border-radius: 5px;
                align-items: center;

                .icon{
                    font-size: 1.5rem;
                    padding: 5px;
                    cursor: pointer;
                }
            }

            .optional{
                color: $secondary;
                font-size: 0.8rem;
            }
        }
    }
}

@media screen and (max-width: 770px) {
    .publicLink{
        .main-content{
            flex-direction: column;
            align-items: center;
        }
    }
}

@media screen and (max-width: 300px) {
    .publicLink{
        .button-wrap{
            flex-direction: column-reverse;
        }
    }
}