.addDataTableGlobal{
    display: flex;
    flex-direction: column;
    gap: 20px;

    .title{
        font-weight: 600;
    }

    .title-input{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .top{
        display: flex;
        flex-direction: column;
        gap: 10px;
        font-size: 1rem;
        font-weight: 600;
    }

    .center{
        display: flex;
        flex-direction: column;
        gap: 10px;

        .content{
            display: flex;
            flex-direction: column;
            gap: 10px;

            .identifier-wrap{
                .wrap-details{
                    display: flex;
                    flex-direction: column;
                    gap: 30px;

                    .attribute-wrap{
                        display: flex;
                        flex-direction: column;
                        gap: 10px;

                        .attr-title{
                            font-weight: 500;
                        }

                        .wrap{
                            display: flex;
                            flex-direction: column;
                            gap: 10px;

                            .colors-wrap{
                                display: flex;
                                flex-direction: column;
                                gap: 10px;
                                
                                .input-wrap{
                                    display: flex;
                                    align-items: center;
                                    gap: 10px;

                                    .title{
                                        width: 100%;
                                        max-width: max-content;
                                        font-weight: 400;
                                        align-self: flex-start;
                                    }
                                }

                                .colors{
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: space-evenly;
                                    align-items: center;
                                    margin-top: 10px;
                                    width: 100%;
                                    max-width: 600px;
                                    align-self: center;
                                    gap: 10px;

                                    .color{
                                        width: 25px;
                                        height: 25px;
                                        border-radius: 5px;
                                        cursor: pointer;
                                        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
                                    }
                                }
                            }

                        }

                    }

                    .button-wrap{
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        gap: 15px;
                        
                        .saved-note{
                            display: flex;
                            align-items: center;
                            gap: 5px;
                            pointer-events: none;
                            opacity: 0;
                            transition: all 0.3s ease;
                            
                            &[data-active="true"]{
                                opacity: 1;
                            }

                            .icon{
                                color: $blue;
                            }
                        }
                    }
                }
            }
        }
    }

    .button-wrap{
        display: flex;
        justify-content: flex-end;
        padding-top: 30px;
    }
}

@media screen and (max-width: 770px) {
    .addDataTableGlobal{
        .center{
            .content{
                .identifier-wrap{
                    .wrap-details{
                        .attribute-wrap{
                            .wrap{
                                .input-wrap{
                                    flex-direction: column;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}