.data-intergration {
  padding: 10px;

  .button:disabled {
    background-color: rgb(176, 166, 149);
    color: #e7e7e7;
    cursor: default;
  }

  .card {
    background-color: $white;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    padding: 10px 10px 10px 10px;
    margin: 20px 25px 0 20px;

    &[data-active="false"] {
      display: none !important;
    }

    .bottom_right_btns {
      margin-top: 30px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: row;
      gap: 20px;
      justify-content: flex-end;
    }

    .title {
      margin-bottom: 20px;
    }

    .delete_section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .section {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &:nth-child(1) {
          .subtitle {
            font-weight: 500;
          }

          .subtitle_desc {
            margin-top: 5px;
            color: rgba(0, 0, 0, 0.6);
            font-size: 0.8rem;
          }
        }

        &:nth-child(2) {
          .button {
            border: 2px solid $red;
            color: $red;
            padding-left: 20px;
            padding-right: 20px;
            background-color: rgba(220, 53, 69, 0.2);
          }
        }
      }
    }

    .bottom {
      margin-top: 30px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    .input_wrap {
      margin-bottom: 20px;

      .title {
        margin-bottom: 10px;
        font-weight: 500;
      }

      .input {
        padding: 10px 15px 10px 15px;
      }

      .input_with_icon_wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 5px;

        .input_icon {
          width: 40px;
          font-size: 1.2rem;
        }

        .input {
          padding: 10px 15px 10px 15px;
        }
      }
    }

    .sub_row {
      background-color: rgba(0, 0, 0, 0.1);

      .sub_row_icon {
        margin-right: 10px;
        font-size: 1.2rem;
      }
    }

    .button_wrap {
      // margin-top: 30px;
      // margin-bottom: 20px;
      display: flex;
      flex-direction: row;
      gap: 20px;
      justify-content: flex-end;
      .button {
        gap: 7px;
      }
    }

    .top_section {
      display: flex;
      justify-content: space-between;

      .title {
        font-size: 1.5rem;
      }

      .device_status {
        .device_status_icon {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          transition: all 0.3s ease;

          &[data-active="true"] {
            color: $green;
            overflow: hidden;

            .active_true {
              animation: blink 1s infinite;
            }
          }

          &[data-active="false"] {
            color: $red;
          }
        }

        .device_status_icon {
          P {
            padding-left: 5px;
            font-size: 0.7rem;
          }
        }
      }
    }
  }

  .note {
    color: $secondary;
    font-size: 0.7rem;
    line-height: 1.5rem;
    margin-bottom: 20px;

    .highlight {
      color: $red;
      background-color: rgba(231, 150, 150, 0.1);
      padding-left: 7px;
      padding-right: 7px;
      word-wrap: break-word;
    }
  }

  .mqtt-server-wrap {
    // .content {
    //     display: flex;
    //     flex-direction: column;
    //     gap: 20px;

    // }

    .input_wrap {
      display: flex;
      gap: 20px;
      align-items: center;

      .button {
        min-width: 120px;
        width: max-content;
        padding: 12px 18px;
        margin-bottom: 12px;
      }
    }
  }

  .double_wrap {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;

    &[data-active="false"] {
      display: none;
    }

    .limit-width {
      max-width: 150px;
    }

    .input_wrap {
      width: 100%;

      .title {
        font-weight: 500;
        margin-bottom: 7px;
      }
    }

    .button {
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 600;
      font-size: 13.33px;
    }
  }

  .identifier-hover-btn {
    border: 0;
    background-color: transparent;
    outline: none;
    cursor: pointer;
  }

  .blusio {
    .card {
      .bluesio_description {
        margin: 10px 0;
        color: #6c757d;
        font-size: 0.8rem;
        line-height: 1.5rem;
      }

      .title_wrap {
        display: flex;
        align-content: center;

        .title_icon {
          margin-left: 10px;
          margin-top: 2px;
          color: rgb(69, 71, 75, 0.6);
          font-weight: 500;
        }
      }

      .input-wrap {
        margin-bottom: 10px;

        .title {
          margin-bottom: 2px;
        }
      }
    }
  }
}

@keyframes blink {
  0%,
  100% {
    background-color: transparent; /* Set initial and final background color */
    border-radius: 0; /* Set initial border radius */
  }

  50% {
    background-color: rgb(130, 205, 71, 0.7); /* Change to your desired background color */
    border-radius: 50%; /* Set the border radius during the animation */
  }
}
