.RightNavBAr {

    .rightPanel {
        height: 100%;
        margin-top: 46px;
    }

    // background: "rgba(230, 230, 230, 0.8)";
    // position: "absolute";
    // right: "0";
    // top: "50px";
    // box-shadow: "-2px -2px 10px rgba(0, 0, 0, 0.3)";
    // border: "1px solid black";
    // min-height: "80vh";
    // // right: isLeftPanelOpen ? "0" : "-250px", // Dynamically set the left position
    // width: "250px";
    // z-index: "1000000";

    .grid_itm_PalleteIcon {
        display: flex;
        color: $blue;
        cursor: pointer;
        -webkit-animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        justify-content: end;
        height: 0;

        &[data-active="false"] {
            display: none;
        }
    }

    .bottom_btn {
        // margin-top: 20px;
        float: right;
        padding: 12px;

    }
    .bottom_btn1 {
        // margin-top: 20px;
        float: right;
        padding: 12px;
        display: flex;

    }
    .bottom_btn_left {
        // margin-top: 20px;
        float: left;
        padding: 12px;

    }

    // .bottom-button-wrap {
    //     display: flex;
    //     justify-content: space-between;
    //     align-items: center;
    //     bottom: -10px;
    //     position: relative;
    // }

    .lineChart_properties {
        .variables-wrap {
            border: 2px solid rgba(0, 0, 0, 0.3);
            border-radius: 5px;
            padding: 8px;
            margin-bottom: 20px;
            width: 100%;

            
        }

        .section {
            .variable_List {
                display: flex;
                flex-direction: column;
                margin-bottom: 20px;

                .varibale_wrap {
                    display: grid;
                    grid-template-columns: 55% 35% 10%;
                    flex-direction: row;
                    width: 100%;
                    border-radius: 5px;
                    padding: 10px 20px 10px 30px;
                    margin-bottom: 5px;
                    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
                    align-items: center;

                    .vw_color {
                        width: 60px;
                        height: 20px;
                        border-radius: 5px;
                    }

                    .vw_icon {
                        color: $red;
                        cursor: pointer;
                    }

                    
                }
            }

            .title {
                font-weight: 500;
                // margin-bottom: 20px;
                margin-top: 20px;
                font-family: Arial, Helvetica, sans-serif;

                .title_desc {
                    color: rgba(0, 0, 0, 0.5);
                    font-size: 0.8rem;
                }
            }

            .button-wrap {
                margin-top: 30px;
            }

            .inputs-wrap {
                display: flex;
                flex-direction: column;
                gap: 40px;
            }

            .input_wrap {
                display: flex;
                flex-direction: row;
                gap: 40px;
            }

            .label_inputs_wrap {
                display: flex;
                flex-direction: column;
                gap: 20px;

                .input_wrap {
                    align-items: center;

                    .title {
                        margin-bottom: 0;
                    }

                    .content {
                        display: flex;
                        align-items: center;
                        gap: 20px;
                        width: 100%;
                    }

                    .input {
                        padding: 10px;
                    }

                    .colour_pick_wrap {
                        width: 100%;
                    }
                }
            }

            .radiobtn_wrap {
                display: flex;
                flex-direction: column;
                grid-template-columns: auto auto auto auto;
                row-gap: 20px;
                margin-top: 20px;
            }

            .colour_pick_wrap {
                display: grid;
                grid-template-columns: auto auto auto auto auto;
                row-gap: 20px;
                margin-bottom: 20px;

                &.no-bottom-margin {
                    margin-bottom: 0;
                }

                .color_radiobtn {
                    width: 40px;
                    height: 40px;
                    border-radius: 5px;
                    cursor: pointer;
                    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
                    transition: all 0.3s ease;

                    &:hover {
                        transform: scale(1.05);
                    }

                    &:active {
                        transform: scale(0.97);
                    }

                    .lcp_cp_label,
                    .bcp_cp_label {
                        display: block;
                        width: 100%;
                        height: 100%;
                        border-radius: 5px;
                        border: 2px solid transparent;
                        cursor: pointer;
                        transition: all 0.3s ease;

                        &[data-active="true"] {
                            border: 2px solid $theme;
                        }
                    }

                    .lcp_cp_radiobtn {
                        display: none;

                        &:checked~label {
                            border: 2px solid $theme;
                        }
                    }

                    .bcp_cp_radiobtn {
                        display: none;
                    }
                }
            }

            .inputs_wrap {
                display: flex;
                flex-direction: row;
                gap: 20px;
                width: 100%;

                .input_wrap {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    width: 100%;

                    .title {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .colors_section{
            margin-bottom: 10px;
        }

        .checked_section{
            .section{
                margin-bottom: -10px;
                font-size: 0.8rem;
            }
        }



        
    }

    #switchSlide {
        .variables-wrap {
            border: 2px solid rgba(0, 0, 0, 0.3);
            border-radius: 5px;
            padding: 8px;
            margin-bottom: 10px;
            width: 100%;   
            display: flex;
            flex-direction: column;
            gap: 12px;
        }

        .top {
            display: flex;
            flex-direction: column;
            margin-top: 50px;
            justify-content: center;
        }

        .content {
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-bottom: 15px;

            .uppercase {
                text-transform: uppercase;
            }
        }

        .note {
            color: $secondary;

            .highlight {
                color: $purple;
            }
        }
    }


    /**boolean slide **/
    .color_picker_section {

        .body {
            display: flex;
            flex-direction: column-reverse;
            justify-content: space-between;

            .options {
                margin-top: 20px;

                .subTitle {
                    color: $dark;
                    font-weight: 500;
                    margin-bottom: 20px;
                }

                .bs_section {
                    margin-bottom: 20px;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .gradient_colors_display {
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        &[data-active="false"] {
                            display: none;
                        }
                    }
                }
            }

        }
    }

    &[data-active="false"] {
        display: none;
    }
}

.bottom_btn1{
    display: flex;
    margin-top:20px;
    justify-content: center;
}

@media screen and (max-width: 770px) {
    .lineChart_properties {
        .section {
            .radiobtn_wrap {
                display: flex;
                flex-direction: column;
            }

            .label_inputs_wrap {
                .input_wrap {
                    flex-direction: column;
                    gap: 5px;
                    align-items: flex-start;
                }

                .colour_pick_input {
                    flex-direction: column;
                }
            }

            .inputs_wrap {
                flex-direction: column;
            }
        }
    }
}

@media screen and (max-width: 770px) {
    .color_picker_section {
        .body {
            display: flex;
            flex-direction: column-reverse;
            justify-content: space-between;

            .options {
                margin-top: 20px;
            }
        }
    }

}