.facePlateContainer {
  margin-top: 2px;
  margin-right: 2px;
  margin-left: 2px;
  background-color: #e0e0e0;

  border: 2px solid #c0c0c0;
  overflow: hidden;

  .faceplate_container_top {
    display: flex;
    align-items: center;
    border-bottom: 2px solid #c0c0c0;
    .first_spped_ref {
    }
    .second_spped_ref {
      margin-top: 20;
    }
    .motor_controls {
      flex: 1;
      margin-left: 15px;
    }
    .manual_mode {
      flex: 1;
      margin-left: 15px;
    }
    .numpad_icon {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  .interlock_container{
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    #variables-wrap {
      border: 2px solid rgba(0, 0, 0, 0.3);
      border-radius: 5px;
      padding: 10px;
      margin-bottom: 20px;
    }
    .section{
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .variable-add{
      width: 100%;
      margin-top: 10px;
      .variables-wrap{
        width: 33%;
        &[data-active="true"]{
          width: 50%;
      }
      }
    }
      .variable_List{
        display: flex;
        flex-direction: column;
        .variable-statement{
          display: flex;
          flex-direction: row;
          margin-top: 10px;
          .identifier-wrap{
            width: 50%;
          }
        }
  
      }



    .status_color_upper {
      background-color: black;
      padding: 5px;
      margin-left: auto;
      width: 30px;
      .status_color_inner {
        border-radius: 10px;
        height: 20px;
        width: 20px;
      }
    }
    .condition_section{
      display: flex;
      flex-direction: column;
      // margin-bottom: 20px;
      margin-top: 20px;
      
      .add_condition_button{
          width: 50px;
      }

      .condition_wrap{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          // margin-bottom: 20px;

          .close_icon{
              font-weight: 700;
              font-size: 1.4rem;
              color: $red;
              cursor: pointer;
          }
      }
      .notification_statement{
        display: flex;
        flex-direction: row;
        gap: 15px;
        align-items: center;
        flex-flow: row wrap;

        .input{
            width: 100px;
            padding: 5px;
        }
    }
  }
  }
  .spped_ref_container {
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: none;
    margin-top: 10px;
    .spped_ref_text {
      flex: 1;
      font-size: 15px;
    }
    .spped_ref_input_container {
      position: relative;
      margin-left: 20px;
      text-align: end;
      max-width: 150px;
    }
    .input-speed-ref-position-feedback[data-active="true"]::after,
    .input-speed-ref-position-setpoint[data-active="true"]::after {
      content: "%";
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      pointer-events: none;
    //   background-color: white; /* Optional: Adjust the background color as needed */
      padding: 0 5px; /* Optional: Adjust the padding as needed */
    }

    .input-speed-ref-position-feedback[data-active="true"] {
      padding-right: 20px; /* Ensure there is space for the percentage mark */
    }

    .input-speed-ref-position-setpoint[data-active="true"] {
      padding-right: 20px; /* Ensure there is space for the percentage mark */
    }
  }
  .facePlate_selector {
    display: flex;
    justify-content: space-around;
    align-items: center;
    .ampere_display {
      width: 5vw;
      height: 5vw; 
      border: none;
      padding: 0 6px;
      border-radius: 50%; 
      background-color: #f0f0f0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-size: 1vw; 
      margin-left: auto;
      margin-right: auto;
      overflow: hidden;
    }

    .ampere_display span {
      display: inline-block; 
      text-align: center; 
      max-width: 100%;
      white-space: normal; 
      word-wrap: break-word; 
  }
  }
  .imagcontainer {
    // max-width:100px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    .icon_container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .icon_dis {
        flex: 1;
        // position: relative;
        .alert_icon_container {
          max-width: 100px;
          position: absolute;
          right: 0;
          top: 10px;
        }
      }
    }
  }
  .motor_container_text {
    text-align: center;
    font-weight: 600;
  }
  .facePlate_dropdown {
    margin: 3px;
  }
  // .speed_ref_one_container{
  //     display: flex;
  // }
  .switch_container {
    display: flex;
    .switch_button {
      width: 55%;
    }
  }
}
// @media screen and (max-width: 380px) {
//    .icon_dis{
//     display: flex;
//     flex-direction: column;
//     position: static;
//    }
// }

.facePlate_top {
  display: flex;
  justify-content: space-between;
  background-color: #e0e0e0;
  padding: 10px;
  border-bottom: 2px solid #c0c0c0;
  .status_color_upper {
    background-color: black;
    padding: 5px;
    margin-left: auto;
    width: 30px;
    .status_color_inner {
      border-radius: 10px;
      height: 20px;
      width: 20px;
    }
  }
}
.facePlate_buttons_container {
  display: flex;
  justify-content: center;
  margin-top: 2px;
  margin-bottom: 2px;
  .facePlate_button {
    width: 130px;
    font-size: medium;
    color: #e0e0e0;
    font-weight: 300;
    padding-left: 40%;
    padding-right: 40%;
  }
  .facePlate_button_save {
    margin-right: 15px;
  }
}
