#changePassword{
    display: flex;
    flex-direction: column;
    gap: 20px;

    .input_wrap{
        min-height: 70px;

        .title{
            margin-bottom: 7px;
        }

        #changePassword-warning{
            font-size: 0.7rem;
            color: $red;
            margin-top: 7px;
            display: none;

            &[data-active="true"]{
                display: block;
            }
        }
    }

    .button_wrap{
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
    }
}