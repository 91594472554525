.message_section{
    display: flex;
    flex-direction: column;
    gap: 20px;

    .input_wrap{
        display: flex;
        flex-direction: column;
        gap: 7px;
        margin-bottom: 25px;

        .title{
            font-weight: 500;
        }

        .input{
            padding: 10px;
        }
    }

    .message_top{
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        align-items: center;
        gap: 15px;
    }

    .message_body{
        background-color: $light2;
        border-radius: 5px;
        padding: 10px;

        &[data-active="false"]{
            display: none;
        }

        .input_wrap{
            .textarea{
                height: 200px;
                resize: none;
            }

            .note{
                font-size: 0.9rem;
                color: $secondary;
            }

            .recivers_container{
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                margin-top: 10px;
                //flex-direction: column;

                .recivers_item{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 7px;

                    .close_icon{
                        color: $red;
                        font-size: 1.1rem;
                        cursor: pointer;
                    }
                }
            }

            .input_box{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 15px;
                flex-flow: row wrap;
                
                .input{
                    width: 250px;
                }

                .icon{
                    font-size: 1.7rem;
                }

                .button{
                    height: auto;
                    width: 150px;
                }
            }
        }
    }
}