.pages_container {

  margin-top: 30px;
  padding: 5px 0px;
  max-height: 180px;
  overflow-y: auto;

  .page_list_top {
    display: flex;
    align-items: center;
    padding-left: 20px;
    justify-content: space-between;

    .device_top_title{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .page_list {
    max-height: 100%;
    overflow-y: auto;
  }

  .page_list .page_list_map{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    
  }

  .editSection .edit_button{
      padding-right: 10px;
      color: blue;
      cursor: pointer;
  }

  .editSection .delete_button{
    padding-right: 10px;
    color: red;
    cursor: pointer;
}

  .page_list_map .editSection{
  display: flex;
  justify-content: space-between;
  align-items: center;
  }

  .page_list li {

    list-style-type: none;
    font-size: 0.9rem;


  }

  .page_list_btn {
    width: 100%;
    padding: 6px 10px;
    text-align: left;
    font-size: 0.7rem;
    padding-left: 46px;
    background-color: transparent;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.09);
    }
  }

  .add_page_button {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    font-weight: 700;
    padding-left: 6px;
    width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
    background-color:transparent;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: transparent;
    }
  }



  .middle_title {
    padding-left: 10px;
    font-weight: 500;
    font-size: 0.9rem;
  }

}