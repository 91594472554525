#geoLocationSlide{

    .note{
        font-size: 0.9rem;
        color: $secondary;
        margin-top: 10px;
    }

    #geoLocationSlide-device-marker-color{
        border-color: $red;
    }

    .color-wrap{
        margin-top: 20px;
        width: 100%;
    }

    .safe-location-wrap{
        display: none;
        flex-direction: column;
        gap: 10px;

        &[data-active="true"] {
            display: flex;
        }

        .content{
            display: flex;
            flex-direction: column;
            gap: 10px;

            .button-wrap{
                display: flex;
                gap: 20px;
                flex-wrap: wrap;
            }
        }

        .title{
            font-weight: 400;
        }

        .location-display-wrap{
            display: flex;
            gap: 40px;
            flex-wrap: wrap;

            .location{
                min-width: 30%;
            }
        }    
    }

    .section {
        .btn_group{
            display: flex;
            justify-content: space-evenly;
            width: 100%;
            border-radius: 7px;
            overflow: hidden;

            .button_grp_set{
                width: 100%;
                border: none;
                background-color:#e8e8e8;
                color: black;
                padding: 5px 0;
                
                
            }

            

            .left_btn{
                border-right: 2px solid white;
            }
            .left_btn:hover{
                cursor: pointer;
            }
    
            .left_btn:active{
                background-color: rgba(0, 0, 0, 0.2);
            }

            .right_btn{
                border: none;
            }

            .right_btn:hover{
                cursor: pointer;
            }
    
            .right_btn:active{
                background-color: rgba(0, 0, 0, 0.2);
            }
        }
    }

    .range-wrap{
        display: flex;
        flex-direction: column;
        gap: 2px;

        .range-limits{
            display: flex;
            flex-direction: column;
            gap: 10px;
            
            .range-inputs-wrap{
                display: flex;
                flex-direction: column;
                gap: 20px;
                
                .content{
                    display: flex;
                    width: 100%;
                    gap: 20px;
                }

                .inputs{
                    width: 100%;
                    display: flex;
                    gap: 20px;
                }
            }
        }

        .button-wrap{
            margin-top: 20px;
        }
    }
}

@media screen and (max-width: 960px) {
    #geoLocationSlide{
        .range-wrap{
            .range-limits{
                .range-inputs-wrap{
                    .inputs{
                        flex-direction: column;
                    }
                }
            }
        }
    }
}