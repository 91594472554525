.geoLocation-widget{
    .offline-container{
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
    }

    .geo-location-marker{
        display: flex;
        flex-direction: column;
        gap: 5px;
        text-align: center;
        font-size: 1rem;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        width: max-content;
        color: $red;

        .geo-location-marker-icon{
            font-size: 2rem;
            color: $red;
        }

        span {
            font-size: 0.5rem;
        }
    }
}