#configure {
  .body {
    .card {
      background-color: $white;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
      border-radius: 5px;
      padding: 30px;
      margin-bottom: 30px;

      &[data-active="false"] {
        display: none !important;
      }

      .title {
        margin-bottom: 20px;
      }

      .delete_section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .section {
          display: flex;
          flex-direction: column;
          justify-content: center;

          &:nth-child(1) {
            .subtitle {
              font-weight: 500;
            }

            .subtitle_desc {
              margin-top: 5px;
              color: rgba(0, 0, 0, 0.6);
              font-size: 0.8rem;
            }
          }

          &:nth-child(2) {
            .button {
              border: 2px solid $red;
              color: $red;
              padding-left: 20px;
              padding-right: 20px;
              background-color: rgba(220, 53, 69, 0.2);
            }
          }
        }
      }

      .bottom {
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }

      .input_wrap {
        margin-bottom: 20px;

        .title {
          margin-bottom: 10px;
          font-weight: 500;
        }

        .input {
          padding: 10px 15px 10px 15px;
        }

        .input_with_icon_wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          background-color: rgba(0, 0, 0, 0.2);
          border-radius: 5px;

          .input_icon {
            width: 40px;
            font-size: 1.2rem;
          }

          .input {
            padding: 10px 15px 10px 15px;
          }
        }
      }

      .sub_row {
        background-color: rgba(0, 0, 0, 0.1);

        .sub_row_icon {
          margin-right: 10px;
          font-size: 1.2rem;
        }
      }

      .button_wrap {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-bottom: 20px;

        .button {
          gap: 7px;
        }
      }
    }

    .public_link {
      .content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 30px;
        align-items: center;

        .note {
          font-size: 0.8rem;
        }

        .button {
          display: flex;
          gap: 10px;
        }
      }
    }

    .change_subscription {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .title {
        margin-bottom: 0px;
        margin-right: 15px;

        .package_name {
          color: $secondary;
        }
      }
    }

    .offline-alert-wrap {
      .input-wrap {
        display: flex;
        gap: 10px;
        margin-bottom: 20px;
      }
    }

    .mqtt-custom-user {
      .input-wrap {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 20px;

        .title {
          margin-bottom: 0;
        }
      }

      .button-wrap {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        justify-content: flex-end;
      }
    }

    .mqtt-server-wrap {
      .content {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .note {
          color: $secondary;
          font-size: 0.8rem;
          line-height: 1.5rem;
          margin-bottom: 20px;

          .highlight {
            color: $red;
            background-color: rgba(231, 150, 150, 0.1);
            padding-left: 7px;
            padding-right: 7px;
            word-wrap: break-word;
          }
        }
      }

      .input_wrap {
        display: flex;
        gap: 20px;

        .button {
          min-width: 150px;
          width: max-content;
        }
      }
    }

    .metadata {
      padding: 0;
      padding: 30px 0 30px 0;

      .title,
      .button-wrap {
        padding: 0 30px 0 30px;
      }

      .content {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .meta-tag {
          display: flex;
          flex-direction: row;
          gap: 10px;
          align-items: flex-start;
          padding: 10px 30px 10px 30px;

          &:nth-child(even) {
            background-color: $light1;
          }

          .tag-button-wrap {
            display: flex;
            flex-direction: row;
            gap: 10px;

            .button {
              font-size: 1.1rem;

              &.delete {
                background-color: $red;
              }
            }
          }

          .input-wrap {
            width: 100%;
            display: flex;
            flex-direction: row;
            gap: 10px;
          }
        }
      }
    }
  }

  .identifier-hover-btn {
    border: 0;
    background-color: transparent;
    outline: none;
    cursor: pointer;
  }
}

@media screen and (max-width: 770px) {
  #configure {
    .body {
      .card {
        .delete_section {
          display: flex;
          flex-direction: column;
          gap: 15px;
        }
      }

      .public_link {
        .content {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
        }
      }

      .mqtt-server-wrap {
        .input_wrap {
          display: flex;
          flex-direction: column;
        }
      }

      .change_subscription {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;
      }

      .metadata {
        .content {
          .meta-tag {
            .tag-button-wrap {
              flex-direction: column;
            }

            .input-wrap {
              flex-direction: column;
            }
          }
        }
      }
    }
  }
}
