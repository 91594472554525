.text_widget{
    display: flex;
    // flex-direction: column;
    gap: 10px;

    .button-wrap{
        display: flex;
        justify-content: flex-end;
    }
    .last_updated_time_left{
        display: flex;
        justify-content: flex-end;
    }
    .button:disabled {
        background-color: gray;
        cursor: not-allowed;
    }

    .input {
        width: 100px;
    }
}
