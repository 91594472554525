#addSim{

    .add-sim-component{
        @include fade-in-animation(0.3s);

        .button-wrap{
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            justify-content: flex-end;
            margin-top: 30px;
        }

        .note {
            margin-top: 10px;
        }

        &[data-active="false"] {
            display: none
        }
    }

    @import './AddSimComponents/simPlan';
    @import './AddSimComponents/details';
    @import './AddSimComponents/activateSim';
}