.rbc-day-bg{
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover{
        background-color: rgba(130, 196, 253, 0.1);
    }
}

@media screen and (max-width: 960px) {
    .rbc-toolbar{
        display: flex;
        flex-direction: column;
    }
}