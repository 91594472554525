#notFound{
    position: fixed;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    text-align: center;
    height: 60vh;
    width: 100%;

    &[data-active="true"] {
        display: flex;
    }

    .text{
        text-decoration: none;
    }
    
    .not_found_image{
        width: 450px;
    }
}

@media screen and (max-width: 770px) {
    #notFound{
        .not_found_image{
            width: 230px;
        }
    }
}