.otherChartsProperties{
    
    .variable_section{
        border: 2px solid rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        padding: 20px;
        margin-bottom: 20px;
    }

    .section{
        .title{
            font-weight: 500;
            margin-bottom: 20px;

            .title_desc{
                color: rgba(0, 0, 0, 0.5);
                font-size: 0.8rem;
            }
        }

        .input_wrap{
            display: flex;
            flex-direction: row;
            gap: 40px;
            
            .input{
                padding: 10px;
            }
        }

        .radiobtn_wrap{
            display: grid;
            grid-template-columns: auto auto auto auto;
            row-gap: 20px;
        }

        .colour_pick_wrap{
            display: grid;
            grid-template-columns: auto auto auto auto auto;
            row-gap: 20px;

            .color_radiobtn{
                width: 40px;
                height: 40px;
                border-radius: 5px;
                cursor: pointer;
                box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
                transition: all 0.3s ease;

                &:hover{
                    transform: scale(1.05);
                }
                
                &:active{
                    transform: scale(0.97);
                }
   
                .ocp_cp_label{
                    display: block;
                    width: 100%;
                    height: 100%;
                    border-radius: 5px;
                    border: 2px solid transparent;
                    cursor: pointer;
                    transition: all 0.3s ease;

                    &[data-active="true"]{
                        border: 2px solid $theme;
                    }
                }

                .ocp_cp_radiobtn{
                    display: none;

                    &:checked ~ label {
                        border: 2px solid $theme;
                    }
                }
            }
        }

        .variable_List{
            display: flex;
            flex-direction: column;
            margin-bottom: 70px;

            .varibale_wrap{
                display: grid;
                grid-template-columns: 55% 35% 10%;
                flex-direction: row;
                width: 100%;
                border-radius: 5px;
                padding: 10px 20px 10px 30px;
                margin-bottom: 5px;
                box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
                align-items: center;

                .vw_color{
                    width: 60px;
                    height: 20px;
                    border-radius: 5px;
                }

                .vw_icon{
                    color: $red;
                    cursor: pointer;
                }
            }
        }

        .btn_group{
            display: flex;
            justify-content: space-evenly;
            width: 100%;
            border-radius: 7px;
            overflow: hidden;

            .button_grp_set{
                width: 100%;
                border: none;
                background-color:#e8e8e8;
                color: black;
                padding: 5px 0;
                
                
            }

            

            .left_btn{
                border-right: 2px solid white;
            }
            .left_btn:hover{
                cursor: pointer;
            }
    
            .left_btn:active{
                background-color: rgba(0, 0, 0, 0.2);
            }

            .right_btn{
                border: none;
            }

            .right_btn:hover{
                cursor: pointer;
            }
    
            .right_btn:active{
                background-color: rgba(0, 0, 0, 0.2);
            }
        }
    }
}