#billingDetails{
    .profile_section{
        display: flex;
        flex-direction: column;
        gap: 20px;

        .input_double_wrap{
            display: flex;
            flex-direction: row;
            gap: 20px;
        }

        .input_section{
            display: flex;
            flex-direction: column;
            gap: 5px;
            width: 100%;

            .title{
                .optional{
                    font-size: 0.8rem;
                    font-weight: 500;
                    color: $secondary;
                }
            }
            
            .double_wrap{
                display: flex;
                align-items: center;
                gap: 10px;
            }
        }

        .button_wrap{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin-top: 20px;
        }
    }
}