.formula-container-wrap{
    display: flex;
    flex-direction: column;
    gap: 10px;
    
    .formula-top{
        display: flex;
        flex-direction: column;
        gap: 10px;

        .title{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            gap: 10px;
            font-weight: 500;
    
            span{
                color: rgba(0, 0, 0, 0.5);
                font-size: 0.9rem;
            }
        }
    }

    .formula-buttons-wrap{
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
    }

    .input_wrap{
        display: flex;
        flex-direction: column;
        gap: 10px;

        .fomular_wrap{
            display: flex;
            flex-direction: row;
            gap: 10px;
            width: 100%;
    
            .input_formula_wrap{
                display: flex;
                flex-direction: column;
                gap: 10px;
                width: 100%;
    
                .display_formula{
                    display: flex;
                    flex-direction: row;
                    flex-flow: row wrap;
                    word-wrap: break-word;
                    width: 100%;
                    gap: 5px;
                    font-size: 0.9rem;
                    margin-bottom: 10px;
                    overflow: hidden;
    
                    .display_formula_item{
                        word-wrap: break-word;
                        max-width: 70%;
    
                        &[data-highlight="true"]{
                            color: red;
                        }
                    }
                }
            }
    
            .button{
                width: max-content;
                height: max-content;
            }
        }
    
        .formula_display{
            margin-top: 20px;
            padding: 10px 20px 10px 20px;
            color: $white;
            border-radius: 7px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 100%;
            font-weight: 500;
            border:2px solid rgba(220, 53, 69, 1);
            background-color: rgba(220, 53, 69, 0.7);
            transition: all 0.3s ease;
    
            &[data-active="false"]{
                display: none;
            }
    
            &[data-status="success"]{
                border-color: rgba(40, 167, 69, 1);
                background-color: rgba(40, 167, 69, 0.7);
            }
        }
    }

}