#content {
  #content-top {
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    position: fixed;
    // top: 0;
    // left: 0;
     width: calc(100vw - 180px); 
    z-index: 1000;
    transition: 0.5s;
    // margin-right: 50px;

    .leftEditToolbar {
      display: flex;
      justify-content: center;
      align-items: center;
      // margin-left: 10px;

      .copyPaste_section {
        margin-left: 10px;

        .buttonsGroup {
          background-color: #050000;
          border-color: #fff;
        }

        .buttonsCP {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.9rem;
          color: #fff;
          background-color: #050000;
          border-color: #fff;
          width: max-content;
          cursor: pointer;
          transition: all 0.3s ease;
          box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
          outline: none;
          padding: 8px 12px;
        }

        .buttonsCP:disabled {
          background-color: rgb(176, 166, 149);
          color: #e7e7e7;
        }
      }

      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 11px;
        margin-right: 5px;
        width: max-content;
        color: #fff;
        background-color: #050000;
        font-size: 0.7rem;
        font-weight: 400;
        border-radius: 5px;
        cursor: pointer;
        transition: all 0.3s ease;
        border: 0;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
        outline: none;
      }

      .buttonsGroup {
        background-color: #050000;
        border-color: #fff;
      }

      .buttonsCP {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.6rem;
        color: #fff;
        background-color: #050000;
        border-color: #fff;
        width: max-content;
        cursor: pointer;
        transition: all 0.3s ease;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
        outline: none;
        padding: 6px 6px;
      }

      .buttonsCP:disabled {
        background-color: rgb(176, 166, 149);
        color: #e7e7e7;
      }

      .fontFamilySection {
        margin-left: 10px;
      }

      .fontSizeSection {
        margin-left: -12px;
      }

      .z_index_section {
        margin-left: 10px;

        .buttonsGroup {
          background-color: #050000;
          border-color: #fff;
        }

        .buttonsCP {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.2rem;
          color: #fff;
          background-color: #050000;
          border-color: #fff;
          width: max-content;
          cursor: pointer;
          transition: all 0.3s ease;
          box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
          outline: none;
          padding: 5.5px 9px;
        }

        .buttonsCP:disabled {
          background-color: rgb(176, 166, 149);
          color: #e7e7e7;
        }
      }

      .zIndex_section {
        margin-left: 5px;
        position: relative;

        .z_index_label {
          position: absolute;
          font-size: 0.6rem;
          top: -6px;
          left: 4px;
          background: #d9d9d9;
          padding: px;
        }

        .z_index_new {
          width: 53px;
          padding: 7px;
          background: #d9d9d9;
          border: 1px solid rgba(128, 128, 128, 0.7);
          border-radius: 3px;
        }
      }

    }

    .fontColor_section {
      margin-right: 5px;

      .fontColor_btn {
        padding: 5px 5px;
        font-size: 1.1rem;
        font-weight: 700;
        min-width: 24px;
        background-color: black;
        color: white;
      }

      .fontColor_btn:disabled {
        background-color: rgb(176, 166, 149);
        color: #e7e7e7;
      }
    }

    .bgColor_section {
      margin-right: 5px;

      .fontColor_btn {
        padding: 4px 5px;
        font-size: 1.1rem;
        font-weight: 700;
        min-width: 24px;
        background-color: black;
        color: white;
        margin-left:5px;
      }

      .fontColor_btn:disabled {
        background-color: rgb(176, 166, 149);
        color: #e7e7e7;
      }
    }
    .lineEditing_section {
      margin-right: 5px;
      position: relative;

      .fontColor_btn {
        padding: 4px 5px;
        font-size: 1.1rem;
        font-weight: 700;
        min-width: 24px;
        background-color: black;
        color: white;
        margin-left:5px;
      }

      .fontColor_btn:disabled {
        background-color: rgb(176, 166, 149);
        color: #e7e7e7;
      }

      .line_edit_panel{
        position: absolute;
        width: 103px;
        height: 72px;
        right: 2px;
        bottom: -79px;
        background-color: green;
      }
    }

    .leftButtons {
      display: flex;
      align-items: "center";
      margin-right: 20px;

      .zoom_button {
        padding: 4px 5px;
        color: white;
        background-color: black;
        border: 1px solid #9e9fa5;
        margin-top: 8px;
      }
      .bg_color_button {
        padding: 0.5px 3px;
        color: white;
        background-color: black;
        border: 1px solid #9e9fa5;
        margin-top: 3px;
        margin-left: 2px;
      }

      .bg_color_button:disabled {
        background-color: rgb(176, 166, 149);
        color: #e7e7e7;
      }
      .Editbutton {
        padding: 0.5px 6px;
        font-size: 0.9rem;
        background-color: black;
        color: white;
        border: 1px solid #9e9fa5;
        margin-right: 3px;
        font-weight: 700;
      }

      .Editbutton:disabled {
        background-color: rgb(176, 166, 149);
        color: #e7e7e7;
      }

      .publink_btn {
        padding: 3px 0px;
        font-size: 1.2rem;
        background-color: black;
        color: white;
        border: 1px solid #9e9fa5;
        font-weight: 700;
      }

      .publink_btn:disabled {
        background-color: rgb(176, 166, 149);
        color: #e7e7e7;
      }

      .ToggleButton {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 4px;
        margin-right: 10px;
      }
    }
  }
  
  #content-top.expanded {
    width: 100vw;
  }

  #content-top::-webkit-scrollbar {
    display: none;
  }

  .content_left {
    height: 83vh;
    // position: "fixed",
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    // margin-left: -20px;
  .mobileFrame{
    margin-top: 70px;
  }
    .left_nav_bar {
      position: relative;
      width: 200px;
      height: auto;
      overflow: scroll;
      margin-top: 46px;

      .leftPanel {
        margin-top: 0px;

        .collapsible_container {
          border-radius: 8px;
          margin-bottom: 10px;

          .chartTypes {
            display: flex;
            flex-direction: row;
            flex-flow: row wrap;
            gap: 15px;
            width: 100%;
            padding: 8px 8px 8px 12px;
            background-color: white;
            // border-right : 1px solid white;

            .ct_item_wrap {
              width: 50px;
              height: 50px;
              background-color: white;
              //background-color: rgb(233, 233, 233);
              /* boxShadow: 0 3px 5px rgba(0, 0, 0, 0.3); */
              /* border: 1px solid transparent; */
              /* borderRadius: 5px; */
              border: 1px solid #e7eaee;
              border-radius: 11px;
              cursor: pointer;
              transition: all 0.3s ease;
              padding: 10px;

              .ct_label_icon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                font-size: 4rem;
              }
            }
          }

          .collapsible_buttons {
            background-color: white;
            color: black;
            cursor: pointer;
            padding: 8px 8px 8px 21px;
            width: 100%;
            border-top: 0.5px solid rgba(0, 0, 0, 0.1);
            border-right: 0.5px solid rgba(0, 0, 0, 0.1);
            border-bottom: none;
            border-left: none;
            text-align: left;
            outline: none;
            font-size: 0.9rem;
            font-weight: 700;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-family: Arial, Helvetica, sans-serif;
          }
        }
      }
    }
  }
}

#content {
  #content-top-mobile {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background-color: #d9d9d9;

    .leftEditToolbar {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 10px 5px;
      position: absolute;
      bottom: 0;
      background-color: #ffffff;
      z-index: 500;

      .copyPaste_section {
        margin-left: 3px;

        .buttonsGroup {
          background-color: #050000;
          border-color: #fff;
        }

        .buttonsCP {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.9rem;
          color: #fff;
          background-color: #050000;
          border-color: #fff;
          width: max-content;
          cursor: pointer;
          transition: all 0.3s ease;
          box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
          outline: none;
          padding: 7px 0px;
        }

        .buttonsCP:disabled {
          background-color: rgb(176, 166, 149);
          color: #e7e7e7;
        }
      }

      .z_index_section {
        margin-left: 10px;

        .buttonsGroup {
          background-color: #050000;
          border-color: #fff;
        }

        .buttonsCP {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.2rem;
          color: #fff;
          background-color: #050000;
          border-color: #fff;
          width: max-content;
          cursor: pointer;
          transition: all 0.3s ease;
          box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
          outline: none;
          padding: 5px 4px;
        }

        .buttonsCP:disabled {
          background-color: rgb(176, 166, 149);
          color: #e7e7e7;
        }
      }

      .Editbutton {
        padding: 1.8px 6px;
        font-size: 0.9rem;
        background-color: black;
        color: white;
        border: 1px solid #9e9fa5;
        margin-left: 3px;
        font-weight: 700;
      }

      .add_widget {
        padding: 1.8px 6px;
        font-size: 0.9rem;
        background-color: black;
        color: white;
        border: 1px solid #9e9fa5;
        margin-left: 3px;
        font-weight: 700;
      }

      .add_widget:disabled {
        background-color: rgb(176, 166, 149);
        color: #e7e7e7;
      }

      .bg_color_button {
        padding: 0.5px 3px;
        color: white;
        background-color: black;
        border: 1px solid #9e9fa5;
        margin-top: 3px;
        margin-left: 2px;
      }

      .bg_color_button:disabled {
        background-color: rgb(176, 166, 149);
        color: #e7e7e7;
      }
      .bg_color_button_border {
        padding: 0.5px 3px;
        color: white;
        background-color: black;
        border: 1px solid #9e9fa5;
        margin-top: 3px;
        margin-left: 2px;
      }

      .bg_color_button_border:disabled {
        background-color: rgb(176, 166, 149);
        color: #e7e7e7;
      }
      .publink_btn {
        padding: 3px 3px;
        color: white;
        background-color: black;
        border: 1px solid #9e9fa5;
        margin-top: 3px;
        margin-left: 2px;
      }

      .publink_btn:disabled {
        background-color: rgb(176, 166, 149);
        color: #e7e7e7;
      }
    }
  }
}
