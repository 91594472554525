.widget_containers {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.label_container {
    .ld_value {
        position: absolute;
        left: $labelLeft;
        top: $labelTop;
        right: $labelRight;
        bottom: $labelBottom;
    }
    .last_updated_time {
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: x-small;
    }
}
.last_updated_time {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: x-small;
}
.last_updated_time_left {
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: x-small;
}
.last_updated_time_font{
    font-size: x-small;
}

.widget_containers {
    .blink-alert-widget{
        animation: blink 1s infinite;
        color: "grey"
        
    }
}

.legend-container {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    margin-top: 10px;
    justify-content: center;

    .legend-item {
        display: flex;
        align-items: center;
        gap: 8px;

        .legend-color {
            width: 16px;
            height: 16px;
            border-radius: 50%;
        }
    }
}

@keyframes fade-out {
    0% {
      opacity: 1;
      transform: scale(0.9);
    }
    100% {
      opacity: 0.7;
      transform: scale(1.1);
    }
  }



@import './booleanDisplay';
@import './label';
@import './geoLocations';
@import './switch';
@import './knob';
@import './tank';
@import './text';
@import './liveStream';
@import './dataTable';
@import './verticleBar';
@import './motorDisplay';
@import './pumpDisplay';