#img_labelDisplay{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: initial;
    // padding: 0.5rem;

    .ld_value{
        // width: 100%;
        // height: 100%;
        // font-size: 4rem;
        // display: flex;
        // flex-direction: row;
        // justify-content: flex-start;
        // color: $dark;
        // white-space: pre-wrap;
        margin-left: 10px;
        // position: absolute;
        right: 0;
        bottom: 0;
        font-size: 4rem;

        &[data-bold="true"] {
            font-weight: 500;
        }
    }

    .img {
        width: 100%;
        height: 100%;
    }
}

#labelDisplay{
    width: 100%;
    height: 100%;
    justify-content: center;
    align-content: center;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;

    .ld_value{
        right: 0;
        bottom: 0;
        font-size: 18px;

        &[data-bold="true"] {
            font-weight: 500;
        }
    }
    .ld_value_bold{
        right: 0;
        bottom: 0;
        font-size: 18px;
        font-weight: 600;
    }
}

#pricelabelDisplay{
    width: 100%;
    height: 100%;
    justify-content: center;
    align-content: center;
    display: flex;
    flex-wrap: wrap;

    .bg_img{
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .ld_value{
        z-index: 1;
        right: 0;
        bottom: 0;
        font-size: 18px;

        &[data-bold="true"] {
            font-weight: 500;
        }
    }
    .ld_value_bold{
        z-index: 1;
        right: 0;
        bottom: 0;
        font-size: 18px;
        font-weight: 600;
    } 
}

#pricelabelFlipDisplay{
    width: 100%;
    height: 100%;
    justify-content: center;
    align-content: center;
    display: flex;
    flex-wrap: wrap;

    .bg_img{
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .ld_value{
        z-index: 1;
        right: 0;
        bottom: 0;
        font-size: 18px;

        &[data-bold="true"] {
            font-weight: 500;
        }
    }
    .ld_value_bold{
        z-index: 1;
        right: 0;
        bottom: 0;
        font-size: 18px;
        font-weight: 600;
    } 
}