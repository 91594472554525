.workspaces_container {

  margin-top: 10px;
  padding: 5px 0px;
  // height: calc(100% - 237px);
  // overflow-y: auto;

  .workspaces_list_top {
    display: flex;
    align-items: center;
    padding-left: 20px;
  }

  .workspaces_list {
    max-height: 100%;
    overflow-y: auto;
  }

  .workspaces_list li {

    list-style-type: none;
    font-size: 0.9rem;


  }

  .page_workspaces_btn {
    width: 100%;
    padding: 6px 10px;
    text-align: left;
    font-size: 0.7rem;
    padding-left: 46px;
    background-color: transparent;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.09);
    }
  }

  .add_workspaces_button {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    font-weight: 700;
    padding-left: 20px;
    width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
    background-color: rgba(0, 0, 0, 0.04);
    border: none;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.09);
    }
  }



  .middle_title {
    padding-left: 10px;
    font-weight: 500;
    font-size: 0.9rem;
  }

}