#gaugeSlide{
    .section{
        margin-bottom: 25px;

        .wrapper{
            display: flex;
            flex-direction: column;
            gap: 2px;
        }

        &.section-flex-column{
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        .gs_title{
            font-weight: 500;
            margin-bottom: 5px;
        }

        .gs_radiobtn_wrap{
            margin-top: 10px;
        }

        .top_input_wrap{
            margin-top: 10px;

            .input{
                padding: 10px 10px 10px 20px;
            }
        }

        .input_wrap{
            margin-top: 10px;
            display: flex;
            flex-direction: row;
            gap: 20px;

            &.input_wrap_column{
                flex-direction: column;
            }

            .wrap{
                width: 100%;
                
                .title{
                    margin-bottom: 7px;
                }

                &.max{
                    .input{
                        width: 100%;
                    }
                }
            }
            
            .input{
                width: 100%;
                padding: 10px 10px 10px 20px;
            }
        }

        &.segment-colors-section{
            .inputs-wrap{
                display: flex;
                flex-direction: column;
                gap: 15px;

                .input-wrap{
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }
            }

            .button-wrap{
                padding-top: 10px;
            }
        }

        .btn_group{
            display: flex;
            justify-content: space-evenly;
            width: 100%;
            border-radius: 7px;
            overflow: hidden;

            .button_grp_set{
                width: 100%;
                border: none;
                background-color:#e8e8e8;
                color: black;
                padding: 5px 0;
                
                
            }

            

            .left_btn{
                border-right: 2px solid white;
            }
            .left_btn:hover{
                cursor: pointer;
            }
    
            .left_btn:active{
                background-color: rgba(0, 0, 0, 0.2);
            }

            .right_btn{
                border: none;
            }

            .right_btn:hover{
                cursor: pointer;
            }
    
            .right_btn:active{
                background-color: rgba(0, 0, 0, 0.2);
            }
        }
    }
}

@media screen and (max-width: 770px) {
    #gaugeSlide{
        .section{
            .input_wrap{
                flex-direction: column;

                .input{
                    width: 100%;
                }
            }
        }
    }
}