#addField, #updateField, #mapField, #updateMapField{
    padding-left: 10%;
    padding-right: 10%;
    overflow: hidden;

    .mf_note{
        color: $secondary;
        padding: 10px 0 10px 0;
        font-size: 0.9rem;
    }

    .body{
        &[data-display="false"]{
            display: none;
        }
    }

    .native_select{
        padding: 10px;
        width: 100%;
        border-radius: 5px;
        margin-bottom: 20px;
    }

    .input_wrap{
        margin-bottom: 20px;

        .title{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 10px;
            font-weight: 500;
            gap: 10px;

            span{
                color: rgba(0, 0, 0, 0.5);
                font-size: 0.9rem;
            }
        }

        .input{
            padding: 10px 15px 10px 15px;
        }

        .note{
            border-radius: 5px;
            border: 2px solid rgba(0, 0, 0, 0.3);
            padding: 0px 10px 7px 10px;
        }

        .input_desc{
            margin-top: 7px;
            color: $secondary;
            font-size: 0.65rem;
        }

        .mode_inputs{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }
    }

    .bottom{
        margin-top: 30px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
        gap: 20px;
        justify-content: flex-end;
    }
}

#mapField, #updateMapField{
    .hr{
        background-color: rgba(0, 0, 0, 0.3);
        width: 100%;
        height: 1px;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .input_map_wrap{
        display: flex;
        flex-direction: row;
        gap: 20px;
        align-items: center;
    }

    .umf_chart_display{
        height: 40vh;
    }

    .mf_chart_display{
        height: 40vh;
    }
}

@media screen and (max-width: 770px) {
    #mapField, #updateMapField{
        .umf_chart_display{
            height: 50vh;
        }

        .mf_chart_display{
            height: 50vh;
        }
    }
}

@media screen and (max-width: 770px) {
    #addField, #updateField, #mapField, #updateMapField{
        .input_wrap{
            margin-bottom: 20px;
    
            .fomular_wrap{
                display: flex;
                flex-direction: column;
                gap: 10px;
                width: 100%;
            }
        }
    }
}