#notifications{
    .body{

        .button_wrap{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin-bottom: 30px;
        }

        .icons{
            font-size: 1.1rem;
            color: $red;

            &[data-active="true"]{
                color: $green;
            }
        }
    }
}