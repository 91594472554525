.pumpDisplay{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    text-align: center;

    .body{
        display: flex;
        align-items: center;
        @include fade-in-animation(0.5s);

        &[data-active="false"]{
            display: none;
        }
    }
    
    .bd_icon{
        transition: all 0.5s ease;
        font-size: 16px;

        &[data-blink="true"]{
            @include blink-in-animation(2s)
        }

    }
}

