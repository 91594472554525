.widget-bg-color{
    display: flex;
    flex-direction: column;
    gap: 20px;

    .gradient_colors{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .MuiFormGroup-root{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
    }

    .button-wrap{
        display: flex;
        justify-content: flex-end;
    }
    .colour_pick_wrap {
        display: grid;
        grid-template-columns: auto auto auto auto auto;
        row-gap: 20px;
        margin-bottom: 20px;
        margin-left: 20px;

        &.no-bottom-margin {
            margin-bottom: 0;
        }

        .color_radiobtn {
            width: 40px;
            height: 40px;
            border-radius: 5px;
            cursor: pointer;
            box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
            transition: all 0.3s ease;

            &:hover {
                transform: scale(1.05);
            }

            &:active {
                transform: scale(0.97);
            }

            .lcp_cp_label,
            .bcp_cp_label {
                display: block;
                width: 100%;
                height: 100%;
                border-radius: 5px;
                border: 2px solid transparent;
                cursor: pointer;
                transition: all 0.3s ease;

                &[data-active="true"] {
                    border: 2px solid $theme;
                }
            }

            .lcp_cp_radiobtn {
                display: none;

                &:checked~label {
                    border: 2px solid $theme;
                }
            }

            .bcp_cp_radiobtn {
                display: none;
            }
        }
    }
}

@media screen and (max-width: 300px) {
    .widget-bg-color{
        .MuiFormGroup-root{
            display: flex;
            flex-direction: column;
            gap: 0;
        }
    }
}