#createReport{
    .input_wrap{
        margin-bottom: 20px;

        .title{
            font-weight: 500;
            margin-bottom: 10px;
        }

        .input{
            padding: 10px;
        }

        .input_schedule_wrap{
            display: flex;
            flex-direction: row;
            flex-flow: row wrap;
            align-items: center;
            gap: 10px;
        }
    }

    .buttons_wrap{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 15px;
        align-items: center;

        .cancel{
            background-color: $red;
        }
    }
}