.simPlan{

    .simPlan-switch{
        margin-bottom: 30px;
    }
    
    .sim-plans-wrap{
        display: flex;
        justify-content: center;
        gap: 20px;
        flex-wrap: wrap;
        @include fade-in-animation(0.3s);

        &[data-active="false"] {
            display: none;
        }
    }

    .sim-plan{
        display: flex;
        flex-direction: column;
        gap: 7px;
        align-items: center;
        padding: 10px;
        width: 100%;
        border-radius: 7px;
        box-shadow: 0 3px 10px rgba(0, 0, 0 , 0.5);
        max-width: 250px;
        cursor: pointer;
        transition: all 0.5s ease;

        &[data-active="true"] {
            border: 2px solid $purple;
            background-color: #6f42c120;
        }

        .title{
            font-weight: bold;
        }

        .features{
            display: flex;
            flex-direction: column;
            gap: 7px;
            max-width: 85%;
            text-align: center;
            font-size: 0.9rem;
        }
        
        .price-wrap{
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            gap: 3px;
            align-items: center;

            .price {
                font-weight: 500;
                font-size: 1.4rem;
            }

            .price-desc{
                font-size: 0.9rem;
                color: $secondary;
                width: 102px;
            }
        }
    }
}