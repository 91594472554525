.tank_widget {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .values-wrap {
        z-index: 2;
        display: flex;
        flex-direction: column;
        // margin-top: 30px;
        gap: 2px;
        font-size: 0.8rem;

        .value {
            display: flex;
            gap: 5px;
        }
    }

    .waterm {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 0%;
        padding-top: 0;
        border-radius: 50px/25px;
        background-color: rgba(160, 160, 160, 0.5);
        transition: all 0.5s ease;
    }

    .rectangle-wrap {
        width: 100%;
        min-height: 150px;
        height: 100%;

        .rectanglem {
            position: relative;
            overflow: hidden;
            margin: 0 auto;
            width: 100%;
            height: 100%;
            // border-radius: 10px;
            background-color: rgba(160, 160, 160, 0.5);
        }

        .waterm {
            border-radius: 0px;
        }
    }

    .cylinder-wrap {
        width: 100%;
        min-height: 10px;
        height: 100%;

        .cylinderm {
            position: relative;
            overflow: hidden;
            margin: 0 auto;
            width: 100%;
            height: 100%;
            border-radius: 50px/25px;
            background-color: rgba(160, 160, 160, 0.5);

            &::before {
                position: absolute;
                left: 0;
                top: 0;
                height: 35px;
                width: 100%;
                border-radius: 50px/25px;
                background-color: rgba(160, 160, 160, 0.2);
                content: "";
            }

            &::after {
                position: absolute;
                left: 0;
                bottom: 0;
                height: 25px;
                width: 100%;
                border-radius: 50px/25px;
                background-color: rgba(160, 160, 160, 0.2);
                content: "";
            }
        }

        .waterm {
            &::before {
                position: absolute;
                left: 0;
                top: 0;
                height: 40px;
                width: 100%;
                border-radius: 50px/25px;
                background-color: rgba(172, 175, 175, 0.4);
                content: "";
            }

            &::after {
                position: absolute;
                left: 0;
                bottom: 0;
                height: 25px;
                width: 100%;
                border-radius: 50px/25px;
                background-color: rgba(116, 116, 116, 0.4);
                content: "";
            }
        }

        .cylinder {
            position: relative;
            overflow: hidden;
            margin: 0 auto;
            width: 100px;
            height: 150px;
            border-radius: 50px/25px;
            background-color: rgba(160, 160, 160, 0.5);

            &::before {
                position: absolute;
                left: 0;
                top: 0;
                width: 100px;
                height: 50px;
                border-radius: 50px/25px;
                background-color: rgba(160, 160, 160, 0.2);
                content: "";
            }

            &::after {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100px;
                height: 50px;
                border-radius: 50px/25px;
                background-color: rgba(160, 160, 160, 0.4);
                content: "";
            }
        }

        .water {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100px;
            height: 0%;
            padding-top: 50px;
            border-radius: 50px/25px;
            background-color: rgba(52, 231, 154, 0.5);
            transition: 0.3s linear;

            &::before {
                position: absolute;
                left: 0;
                top: 0;
                width: 100px;
                height: 50px;
                border-radius: 50px/25px;
                 content: "";
            }

            &::after {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100px;
                height: 50px;
                border-radius: 50px/25px;
                background-color: rgba(52, 231, 154, 0.4);
                content: "";
            }
        }
    }

    .wave {
        margin-top: -45px;
        width: 100%;

        #waveShape {
            display: none;
            //animation-name: wave;
            //animation-iteration-count: infinite;
            //animation-timing-function: linear;
            //animation-duration: 1s;
            width: 100%;
            height: 150px;
        }
    }

    .wave1 {
        margin-top: -4px;
        width: 100%;

         #waveShape{
            display: none;
            //animation-name: wave;
            //animation-iteration-count: infinite;
            //animation-timing-function: linear;
            //animation-duration: 1s;
            width: 100%;
            height: 150px;
        }
    }
}

@keyframes wave {
    0% {
        transform: translateX(-50%);
    }

    100% {
        transform: translateX(0);
    }
}