#deviceSubscriptionPackages {

    .note {
        font-size: 0.9rem;
        color: $secondary;
        margin-bottom: 20px;
        text-align: center;
    }

    .switch-wrap {
        margin: 20px 0 20px 0;
    }

    .coupon-input {
        .wrap {
            display: flex;
            gap: 20px;
        }
    }

    .button-wrap {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .total-wrap {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 20px;
        margin-bottom: 20px;

        .total-section {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            justify-content: space-between;
            gap: 20px;

            &:last-child {
                border-bottom: 2px solid rgba(0, 0, 0, 0.2);
                padding-bottom: 20px;
            }

            .price {
                font-weight: 700;
            }
        }
    }

    .packages_body {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        gap: 20px;

        .package {
            background-color: $white;
            border-radius: 5px;
            box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 20px;
            min-width: 160px;
            min-height: 650px;
            width: 100%;
            max-width: 300px;
            transition: all 0.4s ease;

            &[data-selected="true"] {
                border: 2px solid rgba(155, 39, 218, 1);
                background-color: rgba(155, 39, 218, 0.2);
            }

            .title {
                text-transform: capitalize;
                font-weight: 600;
            }

            .desc,
            .price {
                align-self: center;
            }

            .desc {
                display: flex;
                flex-direction: column;
                gap: 7px;
                text-align: center;
                font-size: 0.8rem;
                text-transform: capitalize;
            }

            .price {
                margin-top: 10px;
                margin-bottom: 10px;

                span {
                    font-size: 0.8rem;
                    font-weight: 600;
                    color: $secondary;
                }
            }
        }
    }
}

@media screen and (max-width: 1036px) {
    #deviceSubscriptionPackages {
        .coupon-input {
            .wrap {
                display: flex;
                flex-direction: column;
            }
        }

        .packages_body {
            flex-direction: column;
        }
    }
}