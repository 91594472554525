#forgot_pwd{
    width: 100vw;
    height: 100vh;
    padding: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    
    .top{
        top: 0;
        left: 0;
        padding: 40px;
        position: absolute;
    }

    .body{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 50%;
        width: 400px;
        gap: 30px;
        @include fade-in-animation(0.3s);

        .input{
            padding: 10px;
        }
    }
}

@media screen and (max-width: 960px) {
    #forgot_pwd{
        padding: 30px;

        .top{
            width: 100%;
            padding: 30px;
        }

        .body{
            width: 100%;
        }
    }
}