.details{

    .inputs{
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-bottom: 70px;

        .title{
            .optional{
                font-size: 0.8rem;
                color: $secondary;
                font-weight: 500;
            }
        }
    }

    .double_wrap{
        display: flex;
        flex-direction: row;
        gap: 20px;

        .input_wrap{
            width: 100%;
        }
    }
}

@media screen and (max-width: 770px) {
    .details{
        .double_wrap{
            flex-direction: column;
            gap: 15px;
        }
    }
}