.counter-comp-wrap{
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    gap: 10px;

    .count-icon{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        color: $white;
        font-weight: bold;
        background-color: rgba(0, 0, 0, 0.3);
        cursor: pointer;
        user-select: none;
    }

    .count{
        min-width: 30px;
        text-align: center;
    }
}