#addDevice{
    max-width: 900px;
    
    .tab_panel{
        display: flex;
        flex-direction: column;
        height: auto;
        padding: 20px;
        max-width: 900px;
        margin-bottom: 10px;

        .top{
            .title{
                text-align: center;
                font-size: 1.5rem;
                font-weight: 600;
                text-transform: capitalize;
            }
        }

        .category-wrap{
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 30px;

            .category{
                display: flex;
                flex-direction: column;
                gap: 5px;
                align-items: center;
                cursor: pointer;
                padding: 10px;
                border: 3px solid $secondary;
                border-radius: 5px;
                min-width: 100px;
                transition: all 0.4s ease;

                &[data-active="true"]{
                    border-color: $blue;
                }

                .icon{
                    font-size: 3rem;
                }
            }
        }

        .button_wrap{
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            gap: 15px;
        }
    }

    @import './AddDeviceComponents/device';
    @import './AddDeviceComponents/product';
    @import './AddDeviceComponents/packages';
    @import './AddDeviceComponents/details';
    @import './AddDeviceComponents/checkout';
}

@media screen and (max-width: 770px) {
    #addDevice{
        .tab_panel{
            .category-wrap{
                .category{
                    min-width: 70px;
                    font-size: 0.8rem;

                    .icon{
                        font-size: 2rem;
                    }
                }
            }
        }
    }
}