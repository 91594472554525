.card_form_details{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;

    .card-section{
        display: flex;
        flex-direction: column;
        gap: 10px;

        &[data-active="false"]{
            display: none;
        }

    }

    .info{
        &[data-active="false"]{
            display: none;
        }
    }

    .title{
        &[data-hide="true"]{
            display: none;
        }
    }

    .card-button-wrap{
        display: flex;
        justify-content: flex-end;

        &[data-hide="true"]{
            display: none;
        }
    }

    .card_main_wrap{
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .card_wrap{
        padding: 10px;
        border-radius: 5px;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);

        &[data-disabled="true"]{
            pointer-events: none;
            opacity: 0.5;
        }
    }

    .checkout_card_details_double{
        display: flex;
        flex-direction: row;
        gap: 20px;
    }

    .card_list{
        display: flex;
        flex-direction: column;
        padding: 10px;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);

        &[data-active="false"]{
            display: none;
        }

        .card_item{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            cursor: pointer;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            transition: all 0.3s ease;
            border-radius: 5px;

            &:nth-child(1){
                border-top: 1px solid $white;
            }

            &[data-active="true"]{
                background-color: rgba(0, 0, 0, 0.1);
            }

            .card_sub_item{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;
            }
        }
    }
}