@mixin scale-in-center-animation($duration: 0.5s){
  -webkit-animation: scale-in-center $duration cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: scale-in-center $duration cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@mixin fade-in-animation($duration: 0.5s){
  -webkit-animation: fade-in $duration cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in $duration cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@mixin blink-in-animation($duration: 0.5s){
  -webkit-animation: blink-2 $duration infinite both;
  animation: blink-2 $duration infinite both;
}

@mixin swing-in-top-fwd($duration: 0.5s, $delay: 0s){
	-webkit-animation: swing-in-top-fwd $duration cubic-bezier(0.175, 0.885, 0.320, 1.275) both $delay;
	        animation: swing-in-top-fwd $duration cubic-bezier(0.175, 0.885, 0.320, 1.275) both $delay;
}

 @-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}
@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}

@-webkit-keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  
  @-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes blink-2 {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes blink-2 {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }