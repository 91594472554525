.device{
    display: flex;
    flex-direction: column;
    @include fade-in-animation();
    
    .content{
        display: flex;
        flex-direction: column;
        align-items: center;
        
        .top{
            font-size: 6rem;
        }

        .body{
            display: flex;
            flex-direction: column;
            gap: 10px;

            .ad_input_wrap{
                display: flex;
                flex-direction: column;
                gap: 5px;

                .ad_input_wrapper{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    background-color: rgba(0, 0, 0, 0.2);
                    border-radius: 5px;
                    
                    .input{
                        min-width: 250px;
                    }
                    
                    .ad_input_icon{
                        margin: 0 10px 0 10px;
                        font-size: 1.2rem;
                    }
                }
            }
        }
    }

}