.popUpContainer{
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: flex-start;
    padding-top: 7rem;
    padding-bottom: 10rem;
    overflow-y: auto;
    @include fade-in-animation(0.1s);
    backdrop-filter: blur(5px);
    z-index: 5000;

    &[data-active="false"]{
        display: none;
    }
    
    .popup_container{
        display: flex;
        flex-direction: column;
        background-color: $white;
        width: max-content;
        border-radius: 5px;
        padding: 15px;
        min-width: 40vw;
        @include fade-in-animation(0.3s);
        //@include scale-in-center-animation(0.3s);
        
        .popup_top{
            display: flex;
            justify-content: space-between;
            margin-bottom: 25px;
            width: 100%;

            .popup_close_icon{
                color: $red;
                cursor: pointer;
            }
        }

        .popup_body{
            width: 100%;
        }
    }
}

.bgpopUpContainer{
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.0);
    width: 100%;
    height: 100%;
    justify-content: right;
    align-items: flex-start;
    padding-top: 7rem;
    padding-bottom: 10rem;
    overflow-y: auto;
    @include fade-in-animation(0.1s);
    // backdrop-filter: blur(-10px);
    z-index: 5000;

    &[data-active="false"]{
        display: none;
    }
    
    .bgpopup_container{
        display: flex;
        flex-direction: column;
        background-color: $white;
        width: fit-content;
        border-radius: 5px;
        padding: 15px;
        min-width: 13vw;
        @include fade-in-animation(0.3s);
        //@include scale-in-center-animation(0.3s);
        
        .bgpopup_top{
            display: flex;
            justify-content: space-between;
            margin-bottom: 25px;
            width: 100%;

            .bgpopup_close_icon{
                color: $red;
                cursor: pointer;
            }
        }

        .bgpopup_body{
            width: 100%;
        }
    }
}

.fontColorPopUpContainer{
    display: flex;
    position: fixed;
    top: -40px;
    right: 261px;
    background-color: rgba(0, 0, 0, 0.0);
    width: 100%;
    height: 100%;
    justify-content: right;
    align-items: flex-start;
    padding-top: 7rem;
    padding-bottom: 10rem;
    @include fade-in-animation(0.1s);
    // backdrop-filter: blur(-10px);
    z-index: 5000;
    overflow-y: scroll;
    scrollbar-width: none;

    &[data-active="false"]{
        display: none;
    }
    
    .bgpopup_container{
        display: flex;
        flex-direction: column;
        background-color: $white;
        width: fit-content;
        border-radius: 5px;
        padding: 15px;
        min-width: 13vw;
        @include fade-in-animation(0.3s);
        //@include scale-in-center-animation(0.3s);
        
        .bgpopup_top{
            display: flex;
            justify-content: space-between;
            margin-bottom: 25px;
            width: 100%;

            .bgpopup_close_icon{
                color: $red;
                cursor: pointer;
            }
        }

        .bgpopup_body{
            width: 100%;
        }
    }
}




@media screen and (max-width: 770px) {
    .popUpContainer{
        padding-top: 4rem;

        .popup_container{
            width: 90vw;
            padding: 0px;
            margin-bottom: 0rem;
            
            .popup_top{
                padding: 15px
            }

            .popup_body{
                padding: 10px;
            }
        }
    }
}
@import './Containers/_Navbar';
@import './Containers/addDevice';
@import './Containers/editWidgetDetails';
@import './Containers/addField';
@import './Containers/confirmationPopUp';
@import './Containers/userAccess';
@import './Containers/addNewWorkSpace';
@import './Containers/addNotificationValue';
@import './Containers/createReport';
@import './Containers/reportsHistroy';
@import './Containers/billingDetails';
@import './Containers/addCardDetails';
@import './Containers/changePassword';
@import './Containers/deviceSubscriptions';
@import './Containers/addMqttServer';
@import './Containers/purchaseWhiteLabel';
@import './Containers/addDataTableGlobal';
@import './Containers/publicLink';
@import './Containers/note';
@import './Containers/calculator';
@import './Containers/widgetBgColor';
@import './Containers/addSim';