#openDashboard{
    position: absolute;
    z-index: 9999;
    width: 100vw;
    min-height: 100vh;
    background-color: $white;
    @include fade-in-animation(0.3s);

    .top{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 0 40px 0 30px;
        width: 100vw;
        height: 4.75rem;

        #open-dashboard-logo{
            max-width: 400px;
            max-height: 3.75rem;
        }
    }

    .backdrop{
        .backDrop_content{
            min-height: 100vh;
            padding-left: 0px;
            padding: 20px;
            justify-content: flex-start;
            perspective: 800px;

            .login-container{
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 15px;
                border-radius: 5px;
                padding: 15px;
                background-color: $white;
                text-align: center;
                width: 100%;
                box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
                max-width: 500px;
                @include swing-in-top-fwd(0.5s, 0.5s);

                .icon{
                    border: 2px solid rgba(0, 0, 0, 0.2);
                    border-radius: 5px;
                    padding: 5px;
                    font-size: 4rem;
                }

                .button-wrap{
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    margin-top: 10px;
                }
            }
        }
    }
    
}

@media screen and (max-wdith: 770px) {
    #openDashboard{
        .backdrop{
            .backDrop_content{
                padding-left: 0px;
            }
        }
    }
}

@media screen and (min-width: 1023px) and (max-width: 1080px) {
    #openDashboard{
        .backdrop{
            .backDrop_content{
                padding-left: 0px;
            }
        }
    }
}