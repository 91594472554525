.color-choices-wrap{
    display: flex;
    width: 100%;
    gap: 40px;
    flex-wrap: wrap;
    align-items: center;
    row-gap: 20px;

    .color-choice{
        width: 30px;
        height: 30px;
        border-radius: 5px;
        transition: all 0.3s ease;
        cursor: pointer;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);

        &:active {
            transform: scale(0.95);
        }
    }
}