$color: rgba(0, 0, 0, 0.2);

#preLoader{
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 9999;
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.circle{
    background-color: rgba(0, 0, 0, 0.3);
    width: 0.2em;
    height: 0.2em;
    border-radius: 50%;
    animation: ripple 0.7s linear infinite;
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba($color, 0.3),
                0 0 0 0.5em rgba($color, 0.3),
                0 0 0 0.7em rgba($color, 0.3),
                0 0 0 1.2em rgba($color, 0.3);
  }
  100% {
    box-shadow: 0 0 0 0.5em rgba($color, 0.3),
                0 0 0 0.7em rgba($color, 0.3),
                0 0 0 1.2em rgba($color, 0.3),
                0 0 0 1.7em rgba($color, 0);
  }
}