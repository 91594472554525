#register{
    background-color: $theme;
    color: $fontColor;

    $secondary: rgba(255, 255, 255, 0.7);
    
    .top{
        padding: 40px;
        position: absolute;
        z-index: 2;
    }

    .body{
        top: 0;
        left: 0;
        display: flex;
        flex-direction: row;
        width: 100vw;
        min-height: 100vh;
        justify-content: center;
        align-items: center;
        position: relative;

        .image-wrap{
            margin-right: 250px;
            width: 0;
            height: 100%;
            overflow: hidden;
            animation: expand 0.5s forwards;
            
            .image{
                object-fit: contain;
                width: 470px;
                height: 100%;
            }
        }

        .card {
            position: absolute;
            background-color: $white;
            box-shadow: 0 3px 10px rgba(193, 193, 193, 0.4);
            padding: 30px;
            border-radius: 5px;
            margin-left: 400px;
            display: flex;
            flex-direction: column;
            gap: 30px;
            width: 350px;
            background-color: $theme;
            @include fade-in-animation(0.3s);

            .register-title{
                display: none;
            }

            .input-wrap{
                display: flex;
                flex-direction: column;
                justify-content: center;
                position: relative;
                width: 100%;
                height: 60px;

                #register-password-warning{
                    font-size: 0.7rem;
                    color: $red;
                    margin-top: 80px;
                    display: none;

                    &[data-active="true"]{
                        display: block;
                    }
                }

                .register-label{
                    position: absolute;
                    font-size: 0.8rem;
                    margin-top: 0px;
                    margin-left: 5px;
                    transition: all 0.3s ease;
                    z-index: 1;
                }

                .bottom{
                    position: absolute;
                    height: 2px;
                    width: 0;
                    bottom: 0; 
                    background-color: rgba(255, 255, 255, 0.4); 
                    margin-bottom: 11px;    
                    transition: all 0.3s ease;
                }

                .register-input{
                    z-index: 2;
                    position: absolute;
                    padding: 10px;
                    margin-top: 0;
                    outline: none;
                    width: 100%;
                    border: 0;
                    background-color: transparent;
                    border-bottom: 2px solid rgba(255, 255, 255, 0.3);
                    color: $fontColor;
                    
                    &:focus, &:valid {
                        ~ label {
                            width: 100%;
                        }

                        + .register-label{
                            transform: translateY(-25px);
                        }
                    }
                }    
            }
            
            .checkbox_wrap{
                display: flex;
                align-items: center;
                gap: 10px;
                font-size: 0.8rem;
                width: 100%;
    
                input, label {
                    cursor: pointer;
                }
            }

            .button{
                background-color: $white;
                color: $theme;
            }

            .button_wrap{
                display: flex;
                flex-direction: column;
                gap: 20px;

                .link-wrap{
                    color: $secondary;
                    font-size: 0.8rem;
                    
                    .link{
                        text-decoration: none;
                        transition: all 0.3s ease;
                        color: $blue;

                        &:hover{
                            color: $purple;
                        }
                    }
                }
            }

        }
    }
}

@media screen and (max-width: 960px) {
    #register{
        padding: 0;

        .top{
        width: 100%;
           padding: 20px; 
        }

        .body{
            flex-direction: column;

            .image{
                display: none;
            }

            .card{
                top: 0;
                left: 0;
                margin: 0;
                width: 100vw;
                min-height: 100vh;
                justify-content: center;

                .register-title{
                    display: block;
                }
            }
        }
    }
}

@keyframes expand {
    0%{
        width: 0;
    }
    100%{
        width: 480px;
    }
}