#tag {
  padding: 10px;
  width: calc(100vw - 180px);

  .note {
    color: $secondary;
    font-size: 0.8rem;
    line-height: 1.5rem;
    margin-bottom: 20px;

    .highlight {
      color: $red;
      background-color: rgba(231, 150, 150, 0.1);
      padding-left: 7px;
      padding-right: 7px;
      word-wrap: break-word;
    }
  }

  .mqtt-server-wrap {
    // .content {
    //     display: flex;
    //     flex-direction: column;
    //     gap: 20px;

    // }

    .input_wrap {
      display: flex;
      gap: 20px;

      .button {
        min-width: 150px;
        width: max-content;
      }
    }
  }

  .double_wrap {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;

    &[data-active="false"] {
      display: none;
    }

    .limit-width {
      max-width: 150px;
    }

    .input_wrap {
      width: 100%;

      .title {
        font-weight: 500;
        margin-bottom: 7px;
      }
    }

    .button {
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 600;
      font-size: 13.33px;
    }
  }

  .identifier-hover-btn {
    border: 0;
    background-color: transparent;
    outline: none;
    cursor: pointer;
  }

  .topTitle {
    .top_title_main {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .top_title_top {
        display: flex;
      }

      .top_title_bottom {
        display: flex;
      }
    }
  }

  .button:disabled {
    background-color: rgb(176, 166, 149);
    color: #e7e7e7;
    cursor: default;
  }

  .delete_button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px 10px 15px;
    width: max-content;
    color: #fff;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    border: 0;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
    outline: none;
    float: right;
    background-color: red;
    margin-right: 10px;
  }

  .delete_button:disabled {
    background-color: rgb(176, 166, 149);
    color: #e7e7e7;
    cursor: default;
  }
}

@media screen and (max-width: 800px) {
  .top_title_main {
    display: flex;
    flex-direction: column;

    .top_title_top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    .top_title_bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 5px;
    }
  }
}

@media screen and (max-width: 400px) {
  #tag {
    padding: 10px;
    width: 100vw;
  }
}
