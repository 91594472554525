#addNotificationValue{
    .input_wrap{
        &[data-active="false"]{
            pointer-events: none;

            .title{
                color: rgba(0, 0, 0, 0.4);
            }
        }
    }

    .button_wrap{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 10px;
    }
}