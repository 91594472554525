#profile{
    .body{
        .profile_form_center{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            #profile_name_tag{
                text-transform: capitalize;
                margin-top: 20px;
            }

            .profile_email_tag{
                font-size: 0.9rem;
                color: $secondary;
                margin-top: 7px;
                margin-bottom: 30px;
            }

            .profile_image_preview{
                width: 250px;
                height: 250px;
                cursor: pointer;
                border: 4px solid $secondary;
                padding: 5px;
                text-align: center;
                border-radius: 50%;
            }
            
        }

        
        .profile_form{
            background-color: $white;
            box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
            margin: 30px 0 30px 0;
            padding: 40px;
            border-radius: 5px;
            
            .profile_input_wrapper{
                margin-bottom: 35px;

                .profile_input_title{
                    margin-bottom: 3px;
                }

                .profile_input_desc{
                    margin-bottom: 10px;
                }
            }
            
            .profile_bottom{
                .button{
                    width: 200px;
                }
            }
        }

        .passwordChangeForm{
            display: flex;
            justify-content: space-between;
        }

        .site_logo_double_wrap{
            display: flex;
            flex-direction: row;
            width: 100%;
            gap: 30px;
            
            .profile_logo_change_wrap{
                background-color: $white;
                box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
                padding: 40px;
                border-radius: 5px;
                width: 100%;
    
                .profile_logo_image_change{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 40px;
                    text-transform: capitalize;
    
                    #profile_logo_input, #profile_icon_logo_input{
                        display: none;
                    }
    
                    .profile_input_title{
                        font-weight: 500;
                    }

                    .profile_site_input_wrap{
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                    }
                }
                
            }
        }

        .profile_input_desc{
            font-size: 0.8rem;
            font-weight: 400;
            color: $secondary;
            text-transform: none;
        }
        

        .profile_site_settings_wrap{

            &[data-active="false"]{
                display: none;
            }

            .profile_site_settings_title{
                margin-bottom: 20px;
            }

            .site_double_wrap{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;
            }
        }
    }

    .delete_section{
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .section{
            display: flex;
            flex-direction: column;
            justify-content: center;

            &:nth-child(1){
                .subtitle{
                    font-weight: 500;
                    margin-top: 10px;
                }

                .subtitle_desc{
                    margin-top: 5px;
                    color: rgba(0, 0, 0, 0.6);
                    font-size: 0.8rem;
                }
            }

            &:nth-child(2){
                .button{
                    border: 2px solid $red;
                    color: $red;
                    padding-left: 20px;
                    padding-right: 20px;
                    background-color: rgba(220, 53, 69, 0.2);
                }
            }
        }
    }

    .token_section{
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .section-left{
            display: flex;
            flex-direction: column;
            justify-content: start;
            width: 80%;
            margin-top: auto;
        }

        .section-right{
            display: flex;
            flex-direction: column;
            justify-content: end;
            margin-top: auto;

            &:nth-child(2){
                .button{
                    border: 2px solid $black;
                    color: $white;
                    padding-left: 20px;
                    padding-right: 20px;
                    background-color: rgba(0, 0, 0);
                }
            }
        }
    }

    .copy{
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .section{
            display: flex;
            flex-direction: column;
            justify-content: center;

            &:nth-child(1){
                .subtitle{
                    font-weight: 500;
                    margin-top: 10px;
                }

                .subtitle_desc{
                    margin-top: 5px;
                    color: rgb(0, 0, 0);
                    font-size: 0.8rem;
                }
            }

            &:nth-child(2){
                .button{
                    border: 2px solid rgb(0, 0, 0);
                    color: rgb(255, 255, 255);
                    margin-top: -40px;
                    margin-left:450px;
                    padding-left: 20px;
                    padding-right: 20px;
                    background-color: rgb(0, 0, 0);
                }
            }
        }
    }



    .apitokenholder{
        margin-top: 20px;
        margin-right: 100px;
        margin-bottom: 1px;

        .profile_input_title{
            margin-bottom: 3px;
        }

        .profile_input_desc{
            margin-bottom: 10px;
        }
    }
    
}





@media screen and (max-width: 770px) {
    #profile{
        .body{

            .profile_form{
                padding: 40px 20px 40px 20px;

                .delete_section{
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                }
            }

            .site_logo_double_wrap{
                flex-direction: column;

                .profile_logo_change_wrap{
                    padding: 20px;
                    
                    .profile_logo_image_change{
                        gap: 20px;
                        flex-direction: column;
                        align-items: flex-start;
                    }
                }
            }

            .profile_site_settings_wrap{
                .site_double_wrap{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                }
            }
        }
    }
}