#home{
    .top{
        display: flex;
        flex-direction: column;
        
        .button_wrap{
            display: flex;
            justify-content: flex-end;

            .button{
                width: max-content;
                padding-top: 10px;
                padding-bottom: 10px;
                gap: 10px;
            }
        }
    }

    .body{

        .device_row{
            &:hover{
                //transition: all 0.5s ease;
                background-color: #eeee;
            }
        }

        .device_status_icon{
            font-size: large;
            transition: all 0.3s ease;

            &[data-active="true"]{
                color: $green;
            }

            &[data-active="false"]{
                color: $red;
            }
        }
    }
}