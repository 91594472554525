#chartSlide,#controllSlide{
    color: $dark;

    .section{
        margin-bottom: 30px;

        .cs_title{
            font-weight: 500;
            margin-bottom: 20px;
        }
    }
}

@import './chartType';
@import './ChartProperties/lineBarChart';
@import './ChartProperties/otherCharts';
@import './ChartProperties/radarchart';