#userAccess{
    display: flex;
    flex-direction: column;
    padding: 0 30px 20px 30px;

    .ua_checkbox_desc{
        margin-left: 7px;
        font-size: 0.8rem;
        color: $secondary;
        margin-bottom: 10px;
    }

    .bottom{
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 15px;
    }
}

@media screen and (max-width: 770px) {
    #userAccess{
        display: flex;
        flex-direction: column;
        padding: 0 20px 20px 20px;
    
        .bottom{
            margin-top: 20px;
        }
    }
}