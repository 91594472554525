#users{
    .top{
        display: flex;
        flex-direction: column;

        .users_search-wrap{
            margin-top: 30px;
            display: flex;
            flex-direction: column;

            .user_invite_wrap{
                display: flex;
                flex-direction: row;
                gap: 20px;

                .button{
                    width: 100px;
                }
            }

            .users_search_label{
                margin-bottom: 10px;
            }
        }
    }
}

@media screen and (max-width: 770px) {
    #users{
        .top{
            .users_search-wrap{
                .user_invite_wrap{
                    flex-direction: column;
                    gap: 10px;
                }
            }
        }
    }
}