.note-data{
    display: flex;
    flex-direction: column;
    gap: 20px;

    .input-wrap{
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 100%;

        .textarea{
            min-width: 100%;
            max-width: 100%;
            min-height: 100px;
        }
    }

    .attachments{
        display: flex;
        flex-direction: column;
        gap: 15px;

        .attachments-top{
            display: flex;
            align-items: center;
            gap: 20px;

            .icon-wrap{
                display: flex;
                align-items: center;
                justify-content: center;
                width: max-content;
                height: max-content;
                cursor: pointer;
                padding: 5px;
                background-color: $light1;
                border-radius: 7px;

                .icon{
                    color: $blue;
                    font-size: 1.2rem;
                }
            }
        }

        .attachments-files{
            display: flex;
            flex-direction: column;
            gap: 10px;

            .file-wrap{
                display: flex;
                gap: 20px;
                justify-content: space-between;
                background-color: $light1;
                align-items: center;
                padding: 10px;

                .name{
                    max-width: 90%;
                }

                .delete{
                    cursor: pointer;
                    font-size: 1.2rem;
                    color: $red;
                }
            }
        }
    }

    .buttons-wrap{
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
    }
}