/* (A) WRAPPER */
#numWrap, #numWrap * { box-sizing: border-box; }
#numWrap {
  /* (A1) FULL SCREEN COVER */
  position: fixed;
  top: 0; left: 0; z-index: 999999;
  width: 100vw; height: 100vh;
// transform: translateY(50%);
  /* (A2) HIDE BY DEFAULT */
//   opacity: 0;
//   visibility: hidden;
//   transition: opacity 0.2s;
    opacity: 1;
    visibility: visible;

  /* (A3) CENTER ON SCREEN + BACKGROUND COLOR */
  display: flex;
  align-items: center; justify-content: center;
  // background: rgba(0, 0, 0, 0.7);
}

/* (A3) SHOW NUMPAD */
// #numWrap{
//   opacity: 1;
//   visibility: visible;
// }

/* (B) NUMPAD */
#numPad {
  max-width: 350px;
  padding: 10px;
  background: #151515;
}

/* (C) DISPLAY */
#numDisplay {
  width: 100%;
  border: 0;
  padding: 5px;
  margin-bottom: 10px;
  background: #000;
  color: #fff;
  font-size: 42px;
  text-align: right;
}
#numDisplay:focus { outline: none; }
#numDisplay::selection { background: none; }

/* (D) BUTTONS WRAPPER */
#numBWrap {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

/* (E) BUTTONS */
#numBWrap div {
  font-size: 24px;
  color: #fff;
  text-align: center;
  padding: 15px 0;
}
#numBWrap div:hover { cursor: pointer; }
#numBWrap .num, #numBWrap .zero, #numBWrap .dot { background: #565656; }
#numBWrap .zero { grid-column: span 2; }
#numBWrap .del, #numBWrap .clr, #numBWrap .plusMinus { background: #333; }
// #numBWrap .cx { background: #940909; }
#numBWrap .ok { background: #115296; }

#numWrap .cx { color: #fff; background: #940909; position: absolute; top: -20px; left: 330px; border-radius: 50%; width: 40px; height: 40px; padding: 12px; align-items: center; text-align: center; display: flex; justify-items: center; cursor: pointer;}

/* (F) NO DECIMAL POINTS ALLOWED */
// #numWrap.noDec .dot { display: none; }
// #numWrap.noDec .zero { grid-column: span 3; }

/* (X) DOES NOT MATTER */
/* PAGE & BODY */
// * {
//   font-family: arial, sans-serif;
//   box-sizing: border-box;
// }
#numpadContainer {
  display: flex;
  align-items: center; justify-content: center;
  min-height: 100%;
  
  // background-image: url(https://images.unsplash.com/photo-1530293959042-0aac487c21e3?crop=entropy&cs=tinysrgb&fm=jpg&ixid=MnwzMjM4NDZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2NzgwOTU1MTg&ixlib=rb-4.0.3&q=80);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media screen and (max-width: 770px){
  #numWrap{
    width: 60vw;
    // height: 80vh;
    left: auto;

  }
  } 
// #demoA, #demoB {
//   width: 100%;
//   padding: 10px;
//   border: 0;
//   margin: 10px 0;
//   resize: none;
// }

/* WIDGET */
// .widget-wrap {
//   min-width: 600px;
//   padding: 30px;
//   border-radius: 20px;
//   background: rgba(0, 0, 0, 0.1);
// }

// /* FOOTER */
// #code-boxx {
//   font-weight: 600;
//   margin-top: 50px;
// }
// #code-boxx a {
//   display: inline-block;
//   padding: 5px;
//   text-decoration: none;
//   background: #b90a0a;
//   color: #fff;
// }