
.table{
    // margin-right: 10px;
    padding:10px;
    width: 100%;
    opacity: 0.97;
}

.table-head{
    font-size: large;
    font-weight: lighter;
    padding: auto;
}

.table-cell-border{
    border: 2px solid rgba(195, 190, 190, 0.686);
}


.table-body-row{
    border: 2px solid rgba(195, 190, 190, 0.686);
}

.table-cell{
    text-align: left;
}