#reports{

    .top_button_wrap{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 15px;
    }

    .body{
        .state_point{
            color: $red;
            font-size: 1.1rem;
            transition: all 0.3s ease;

            &[data-active="true"]{
                color: $green;
            }
        }
    }
}