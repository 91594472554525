#iconPicker{
    width: 100%;
    height: 40vh;
    display: flex;
    flex-direction: column;

    .top{
        .ip_search{
            padding: 10px;
        }
    }

    .body{
        display: grid;
        grid-template-columns: auto auto auto auto auto;
        row-gap: 10px;
        justify-content: space-evenly;
        overflow-y: auto;
        height: 100%;

        .ip_wrap{

            .ip_label{
                width: 45px;
                height: 45px;
                display: flex;
                border-radius: 5px;
                align-items: center;
                justify-content: center;
                font-size: 2rem;
                background-color: $white;
                box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
                cursor: pointer;
                transition: all 0.2s ease;
                border: 2px solid transparent;
    
                &:hover{
                    transform: scale(1.05);
                }
        
                &:active{
                    transform: scale(0.98);
                }
            }

            .ip_radio_btn{
                display: none;

                &:checked ~ label{
                    border-color: $theme;
                    background-color: rgba(98, 0, 238, 0.2);
                }
            }
        }
    }
    
}