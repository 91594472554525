#liveStreamSlide{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
    
    .section {
        .btn_group{
            display: flex;
            justify-content: space-evenly;
            width: 100%;
            border-radius: 7px;
            overflow: hidden;

            .button_grp_set{
                width: 100%;
                border: none;
                background-color:#e8e8e8;
                color: black;
                padding: 5px 0;
                
                
            }

            

            .left_btn{
                border-right: 2px solid white;
            }
            .left_btn:hover{
                cursor: pointer;
            }
    
            .left_btn:active{
                background-color: rgba(0, 0, 0, 0.2);
            }

            .right_btn{
                border: none;
            }

            .right_btn:hover{
                cursor: pointer;
            }
    
            .right_btn:active{
                background-color: rgba(0, 0, 0, 0.2);
            }
        }
    }
}