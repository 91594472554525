#addProduct{
    @include fade-in-animation();

    #productSetting{
        .product-type-wrap{
            display: flex;
            flex-direction: row;
            gap: 20px;
            justify-content: center;
            padding-top: 20px;
            padding-bottom: 30px;
            
            .type-wrap{
                display: flex;
                flex-direction: column;
                gap: 10px;
                max-width: 170px;
                padding: 10px;
                border: 3px solid $secondary;
                transition: all 0.4s ease;
                border-radius: 5px;
                cursor: pointer;

                &[data-active="true"]{
                    border-color: $blue;
                }

                .title{
                    font-weight: 500
                }

                .desc{
                    font-size: 0.9rem;
                    color: $secondary;
                }
            }
        }
        .save-button-wrap{
            display: flex;
            justify-content: space-between;
        }
        .product-wrap{
            display: flex;
            flex-direction: column;
            gap: 20px;

            &[data-active="false"]{
                display: none;
            }

            .desc-wrap{
                display: flex;
                justify-content: center;

                .desc{
                    text-align: center;
                    max-width: 500px;
                    width: 100%;
                    color: $secondary;
                }
            }

            .alert{
                align-self: center;
                max-width: 250px;
                width: 100%;
                background-color: rgba(255, 193, 7, 0.5);
                border-radius: 5px;
                padding: 10px;
                display: flex;
                flex-direction: column;
                gap: 10px;
                color: rgb(125, 88, 2);
                align-items: center;

                .alert-title{
                    font-weight: 500;
                }
            }

            .products-list{
                display: flex;
                flex-direction: column;
                gap: 10px;
                
                .available-product-wrap{
                    background-color: $white;
                    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
                    padding: 10px;
                    border-radius: 5px;
                    display: flex;
                    justify-content: space-between;
                }
            }
        }
    }

    #deviceSetting{
        display: flex;
        flex-direction: column;
        gap: 20px;

        .desc-wrap{
            display: flex;
            justify-content: center;

            .desc{
                text-align: center;
                max-width: 500px;
                width: 100%;
                color: $secondary;
            }
        }

        .devices-collection{
            display: flex;
            flex-direction: column;
            gap: 10px;
            
            .device-wrap{
                display: flex;
                align-items: center;
                gap: 10px;

                .delete{
                    &[data-active="true"]{
                        background-color: $red;
                    }
                }
                
                .input-wrap{
                    display: flex;
                    align-items: center;
                    width: 100%;
                    background-color: $light1;
                    border-radius: 5px;

                    .icon{
                        font-size: 1.2rem;
                        color: $secondary;
                        margin-left: 5px;
                        margin-right: 5px;
                    }

                }
            }
        }
    }
}

@media screen and (max-width: 770px) {
    #addProduct{
        #deviceSetting, #productSetting{
            .desc-wrap{
                .desc{
                    font-size: 0.8rem;
                }
            }
        }

        #deviceSetting{
            .devices-collection{
                .device-wrap{
                    flex-direction: column;
                    align-items: flex-end;
                }
            }
        }
        
    }
}