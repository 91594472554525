#workspace{
    .card{
        box-shadow: 0 3px 5px rgba(0,0, 0, 0.2);
        border-radius: 5px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        .title{
            margin-bottom: 15px;
        }

        .input{
            padding: 10px;
            margin-bottom: 30px;
        }

        .button{
            align-self: flex-end;
        }
        
        .logo_wrapper_form{
            display: flex;
            flex-direction: row;
            gap: 30px;
    
            #workspace_logo_preview{
                display: none;
            }
        
            .workspace_logo_label{
                width: 150px;
                height: 150px;
                border: 2px dashed $secondary;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        
            .workspace_image_preview_icon{
                color: $secondary;
            }
        
            #workspace_logo_preview_img{
                max-width: 150px;
                max-height: 150px;
                border-radius: 5px;
                transition: all 0.3s ease;
            }
        }

        .delete_section{
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .section{
                display: flex;
                flex-direction: column;
                justify-content: center;

                &:nth-child(1){
                    .subtitle{
                        font-weight: 500;
                    }

                    .subtitle_desc{
                        margin-top: 5px;
                        color: rgba(0, 0, 0, 0.6);
                        font-size: 0.8rem;
                    }
                }

                &:nth-child(2){
                    .button{
                        border: 2px solid $red;
                        color: $red;
                        padding-left: 20px;
                        padding-right: 20px;
                        background-color: rgba(220, 53, 69, 0.2);
                    }
                }
            }
        }
    }
    
    .workspace_form{
        background-color: $white;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
        margin: 30px 0 30px 0;
        padding: 40px;
        border-radius: 5px;
        
        .workspace_input_wrapper{
            margin-bottom: 35px;

            .workspace_input_title{
                margin-bottom: 3px;
            }

            .workspace_input_desc{
                margin-bottom: 10px;
            }
        }
        
        .workspace_bottom{
            .button{
                width: 200px;
            }
        }
    }
    
    .site_logo_double_wrap{
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 30px;
        
        .workspace_logo_change_wrap{
            background-color: $white;
            box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
            padding: 40px;
            border-radius: 5px;
            width: 100%;

            .workspace_logo_image_change{
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 40px;
                text-transform: capitalize;

                #workspace_logo_input, #workspace_icon_logo_input{
                    display: none;
                }

                .workspace_input_title{
                    font-weight: 500;
                }

                .workspace_site_input_wrap{
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                }
            }
            
        }
    }

    .workspace_input_desc{
        font-size: 0.8rem;
        font-weight: 400;
        color: $secondary;
        text-transform: none;
    }
}

@media screen and (max-width: 1036px) {
    #workspace{
        .workspace_form{
            padding: 40px 20px 40px 20px;
        }

        .site_logo_double_wrap{
            flex-direction: column;

            .workspace_logo_change_wrap{
                padding: 20px;
                
                .workspace_logo_image_change{
                    gap: 20px;
                    flex-direction: column;
                    align-items: flex-start;
                }
            }
        }

        .workspace_site_settings_wrap{
            .site_double_wrap{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }
}

@media screen and (max-width: 770px) {
    #workspace{
        .card{
            .delete_section{
                display: flex;
                flex-direction: column;
                gap: 15px;

                .section{
                    .button{
                        align-self: flex-start;
                    }
                }
            }
        }
    }
}