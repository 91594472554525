.radioButton{

    .rd_label{
        border: 2px solid $theme;
        padding: 5px 20px 5px 20px;
        cursor: pointer;
        transition: all 0.3s ease;
    }

    .rd_button{
        display: none;

        &:checked ~ label {
            background-color: $theme;
            color: $white;
        }
    }
}