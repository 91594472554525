.gridLayout {
    // margin-top: 20px;
    padding-left: 0px;
    position: relative;

    .grid_item {
        display: flex;
        flex-direction: column;
        // max-width: 85vw;
        width: 100%;
        height: 100%;
        background-color: inherit;
        border-radius: 7px;
        position: absolute;
        // overflow: hidden;
        

        .react-resizable-handle {
            &::after {
                border: unset;
                right: 3px;
                bottom: 3px;
            }
        }

        .grid_itm_container {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            // border-radius: 7px;

           /* &[data-active="true"] {

                &[data-selected="true"] {
                    border: 1.5px #0d6ede solid;
                }

                &[data-selected="false"] {

                    &:hover {
                        border: 1px #a5a5a5 solid;
                    }

                };
            }*/

            /*&[data-mobile="true"] {
                border: 1.5px #0d6ede solid;
            }*/


        }

        /*&[data-active="true"] {
            cursor: grab;

            &:active {
                cursor: grabbing;
            }

            &:hover{
                border: 1px black solid;
            }
        }*/

        .grid_itm_top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: max-content;
            // margin-bottom: 20px;
            color: $dark;
            flex-wrap: wrap;
            gap: 10px;

            .grid_icns_wrap {
                display: flex;
                flex-direction: row;
                //flex-wrap: wrap;
                gap: 20px;
                font-size: 1.2rem;
                z-index: 10001;
            }

            .grid_itm_PalleteIcon {
                display: flex;
                color: $blue;
                cursor: pointer;
                -webkit-animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
                animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

                &[data-active="false"] {
                    display: none;
                }
            }

            .grid_itm_EditIcon {
                display: flex;
                color: $blue;
                cursor: pointer;
                -webkit-animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
                animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

                &[data-active="false"] {
                    display: none;
                }
            }

            .grid_itm_closeIcon {
                display: flex;
                color: $red;
                cursor: pointer;
                -webkit-animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
                animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

                &[data-active="false"] {
                    display: none;
                }
            }
        }

        .grid_item_body {
            height: 101.5%;
            width: 100.5%;
            position: absolute;
            // padding-bottom: 10px;
        }
    }
}

.moveable-control {
    width: 10px!important;
    height: 10px!important;
    margin-top: -5px!important;
    margin-left: -5px!important;
}

@media screen and (max-width: 770px) {

    .gridLayout {
        margin-top: 20px;
    
        .grid_item {
            display: flex;
            flex-direction: column;
            // max-width: 85vw;
            max-width: 98%;
            height: 100%;
            background-color: inherit;
            border-radius: 7px;
            // overflow: hidden;
    
            .react-resizable-handle {
                &::after {
                    border: unset;
                    right: 3px;
                    bottom: 3px;
                }
            }
    
            .grid_itm_container {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 100%;
                // border-radius: 7px;
    
               /* &[data-active="true"] {
    
                    &[data-selected="true"] {
                        border: 1.5px #0d6ede solid;
                    }
    
                    &[data-selected="false"] {
    
                        &:hover {
                            border: 1px #a5a5a5 solid;
                        }
    
                    }
                }*/
    
    
            }
    
            /*&[data-active="true"] {
                cursor: grab;
    
                &:active {
                    cursor: grabbing;
                }
    
                // &:hover{
                //     border: 1px black solid;
                // }
            }*/
    
            .grid_itm_top {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                height: max-content;
                // margin-bottom: 20px;
                color: $dark;
                flex-wrap: wrap;
                z-index: 10002;
                gap: 10px;
    
                .grid_icns_wrap {
                    display: flex;
                    flex-direction: row;
                    //flex-wrap: wrap;
                    gap: 20px;
                    font-size: 1.2rem;
                    z-index: 10001;
                }
    
                .grid_itm_PalleteIcon {
                    display: flex;
                    color: $blue;
                    cursor: pointer;
                    -webkit-animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
                    animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    
                    &[data-active="false"] {
                        display: none;
                    }
                }
    
                .grid_itm_EditIcon {
                    display: flex;
                    color: $blue;
                    cursor: pointer;
                    -webkit-animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
                    animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    
                    &[data-active="false"] {
                        display: none;
                    }
                }
                .grid_itm_InfoIcon {
                    display: flex;
                    background: transparent;
                    cursor: pointer;
                    -webkit-animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
                    animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
                }
    
                .grid_itm_closeIcon {
                    display: flex;
                    color: $red;
                    cursor: pointer;
                    -webkit-animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
                    animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    
                    &[data-active="false"] {
                        display: none;
                    }
                }
            }
    
            .grid_item_body {
                height: 100%;
                width: 100%;
                position: absolute;
                // padding-bottom: 10px;
            }
        }
    }
    
}