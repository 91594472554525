#login{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 40px;
    background-color: $loginTheme;
    color:$loginFontColor;
    

    $secondary: rgba(255, 255, 255, 0.7);
    
    .content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        width: 350px;
        @include fade-in-animation(0.3s);
        
        .logo {
            object-fit: contain;
            width: 100%;
            max-width: 300px;
            margin-bottom: 30px;
        }
        
        .input-wrap{
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
            width: 100%;
            height: 60px;

            .input-label{
                position: absolute;
                font-size: 0.8rem;
                margin-top: 0px;
                margin-left: 5px;
                transition: all 0.3s ease;
                z-index: 1;
            }

            .bottom{
                position: absolute;
                height: 2px;
                width: 0;
                bottom: 0; 
                background-color: #d8dde2;
                margin-bottom: 11px;    
                transition: all 0.3s ease;
            }

            .login-input{
                z-index: 2;
                position: absolute;
                padding: 10px;
                margin-top: 0;
                outline: none;
                width: 100%;
                border: 0;
                background-color: transparent;
                border-bottom: 2px solid $inputBorderBottom;
                color: $loginFontColor;
                
                &:focus, &[data-active="true"], &:valid {
                    ~ .bottom {
                        width: 100%;
                    }

                    + .input-label{
                        transform: translateY(-25px);
                    }
                }
            }

            .forgot-password{
                text-decoration: none;
                font-size: 0.7rem;
                font-weight: 600;
                align-self: flex-end;
                color: $forgotPassword;
                margin-top: 70px;
            }
        }
    
        .checkbox_wrap{
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 0.8rem;
            width: 100%;
            margin-top: 10px;

            input, label {
                cursor: pointer;
            }
        }
    
        .button{
            margin-top: 20px;
            width: 250px;
            background-color: $loginButton;
            color: $loginTheme;
        }

        .link-wrap{
            color: $forgotPassword;
            font-size: 0.8rem;
            
            .link{
                text-decoration: none;
                transition: all 0.3s ease;
                color: $blue;

                &:hover{
                    color: $purple;
                }
            }
        }
    }

}