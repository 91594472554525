.identifierListItem{
    font-weight: 400;
    background-color: #e9e5e5;
    margin-bottom: 3px;
    color: #424242ff;
    border-radius: 5px;
    text-align:left;

    &:hover{
        color: rgb(250, 94, 94);
        background-color: #fdf4f4ff;
        border-radius: 5px;
        
    }
}

.identifierBox{
    &:hover{
        border-color: black;
        
    }
    background-color: "white";
    width: 15%;
    border: 2px solid #c4c4c4ff;
    border-radius: 5px;
    margin-left: 10px;
    padding: 5px;
    overflow:scroll;
    max-height:30vh;
    overflow-x: hidden;
}
.searchBar{
    &::placeholder{
        font-size: 5px;
    }
}