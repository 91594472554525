.checkout{
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 10px;

    .addDeviceBackDrop{
        position: absolute;
        display: flex;
        width: 100%;
        min-height: 90vh;
        background-color: rgba(0, 0, 0,0.2);
    }

    .content{
        display: flex;
        flex-direction: column;
        gap: 20px;

        .section{
            display: flex;
            flex-direction: column;
            gap: 5px;

            .information{
                text-transform: capitalize;
            }

            .title{
                font-weight: 600;
                margin-bottom: 10px;
            }
        }
        
        .checkout_form{
            display: flex;
            flex-direction: row;
            gap: 20px;

            .checkout_form_label{
                display: flex;
                flex-direction: column;
                gap: 5px;
            }

            #checkout_card{
                width: 250px;
            }
            
            .double_wrap{
                display: flex;
                flex-direction: row;
                gap: 25px;

                #checkout_expire, #checkout_cvc{
                    width: 100px;
                }
            }
        }
    }
}

@media screen and (max-width: 960px) {
    .checkout{
        .content{
            .checkout_form{
                flex-direction: column;
            }
        }
    }
}