#device{
    color: $dark;

    .info{
        background-color: $light;
        border-radius: 5px;
        padding: 20px 30px 20px 30px;
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .info_title{
            margin-bottom: 15px;
        }

        .info_wrap{
            background-color: $white;
            padding: 20px;
            box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
            border-radius: 5px;
            width: 100%;
            
            p{
                margin-bottom: 5px;
            }

            h3{
                word-wrap: break-word;
            }
        }

        .info_dynamic_wrap{
            display: flex;
            flex-direction: row;
            flex-flow: row wrap;
            justify-content: space-between;
            gap: 20px;
            row-gap: 20px;
            width: 100%;

            .info_wrap{
                width: 100%;
                max-width: 48%;
            }
        }
    }

    .buttons_wrap{
        .top_buttons{
            display: flex;
            flex-direction: row;
            flex-flow: row wrap;
            width: 100%;
            justify-content: flex-end;
            align-items: center;
            transition: all 0.3s ease;
            gap: 10px;

            .top_button_wrap{
                &[data-active="false"]{
                    display: none;
                }
            }
    
            .top_button{
                padding: 10px 15px 10px 15px;
                outline: none;
                border: none;
                background-color: $theme;
                color: $white;
                font-weight: 600;
                box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
                border-radius: 5px;
                cursor: pointer;
                transition: all 0.2s ease;
                display: flex;
                gap: 4px;
                align-items: center;
    
                &:active{
                    transform: scale(0.97);
                }
    
                &[data-active="true"]{
                    display: flex;
                }
    
                &[data-active="false"]{
                    display: none;
                }
            }
        }       
    }
}

@media screen and (max-width: 960px) {
    #device{
        .info{
            .info_dynamic_wrap{
                .info_wrap{
                    max-width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 770px) {
    #device{
        .info{
            .info_wrap{
                h3{
                    font-size: 1rem;
                }
            }
        }
    }
}