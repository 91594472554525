#reset_pwd{
    width: 100vw;
    height: 100vh;
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .input_wrap{
        text-align: left;
        width: 100%;
        
        #resetPwd-password-warning{
            margin-top: 5px;
            font-size: 0.8rem;
            color: $red;
            font-weight: 500;

            &[data-active="false"]{
                display: none;
            }
        }
    }
    
    .body{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 400px;
        gap: 30px;
        @include fade-in-animation(0.3s);

        .input{
            width: 100%;
            padding: 10px;
        }

        .button{
            width: 150px;
        }

        .link-wrap{
            color: $secondary;
            font-size: 0.8rem;
            
            .link{
                text-decoration: none;
                transition: all 0.3s ease;
    
                &:hover{
                    color: $dark-1;
                }
            }
        }
    }
}

@media screen and (max-width: 960px) {
    #reset_pwd{
        .body{
            width: 100%;
        }
    }
}