.knob-widget{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;

    .knob-label-wrap{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        .knob-label{
            font-weight: bold;
            font-size: 0.9em;
            position: absolute;
        }
    }

    .knob-wrap{
        position: relative;
    }
}