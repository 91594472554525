#billing{

    .body{
        display: flex;
        flex-direction: column;
        transition: all 0.5s ease;
    }

    .card_item{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;
    }

    .section{
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-top: 30px;
        padding-bottom: 30px;
        border-top: 1px solid rgba(0, 0, 0, 0.2);

        &:nth-child(1){
            padding-top: 10px;
            border-top: 1px solid transparent;
        }

        .section_top{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 20px;

            .button_wrap{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 20px;
            }
        }
        
        .billing_details_body{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-flow: row wrap;
            align-items: flex-start;
            gap: 20px;
            
            .address_wrap{
                display: flex;
                flex-direction: column;
                
                .address_item{
                    display: flex;
                    flex-flow: row wrap;
                    max-width: 90%;
                    overflow-x: hidden;

                    &[data-active="false"]{
                        display: none;
                    }
                }
            }
        }

        .section_body{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            width: 100%;
            gap: 25px;
            margin-top: 10px;
            @include fade-in-animation();
            
            &[data-active="false"]{
                display: none;
            }

            .price_per_month{
                font-size: 0.8rem;
                color: $secondary;
            }

            .icon{
                font-size: 1.1rem;
                color: $red;

                &[data-active="true"]{
                    color: $green;
                }
            }

            .package{
                width: 90%;
                max-width: 300px;
                background-color: $white;
                border-radius: 5px;
                box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
                padding: 20px;
                display: flex;
                flex-direction: column;
                gap: 20px;

                .title{
                    font-weight: 600;
                }

                .desc, .price{
                    align-self: center;
                }

                .price{
                    margin-top: 10px;
                    margin-bottom: 10px;

                    span{
                        font-size: 0.8rem;
                        font-weight: 600;
                        color: $secondary;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 770px) {
    #billing{
        .profile_section{
            .input_double_wrap{
                flex-direction: column;
            }
        }
        .section{
            .section_body{
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    #billing{
        .section{

            .section_body{
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }
}