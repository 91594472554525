#booleanSlide {
    min-height: 40vh;
    max-width: 600px;

    .bs_section {
        margin-bottom: 25px;

        .bs_top {
            margin-bottom: 10px;

            .title {
                font-size: 1rem;
                color: $dark;
                font-weight: 500;
            }
        }

        &:nth-child(1) {
            .input {
                padding: 10px;
            }
        }

        .btn_group{
            display: flex;
            justify-content: space-evenly;
            width: 100%;
            border-radius: 7px;
            overflow: hidden;

            .button_grp_set{
                width: 100%;
                border: none;
                background-color:#e8e8e8;
                color: black;
                padding: 5px 0;
                
                
            }

            

            .left_btn{
                border-right: 2px solid white;
            }
            .left_btn:hover{
                cursor: pointer;
            }
    
            .left_btn:active{
                background-color: rgba(0, 0, 0, 0.2);
            }

            .right_btn{
                border: none;
            }

            .right_btn:hover{
                cursor: pointer;
            }
    
            .right_btn:active{
                background-color: rgba(0, 0, 0, 0.2);
            }
        }

    }
    .condition_section{
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        
        .add_condition_button{
            width: 50px;
        }

        .condition_wrap{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;

            .close_icon{
                font-weight: 700;
                font-size: 1.4rem;
                color: $red;
                cursor: pointer;
            }
        }

        .notification_statement{
            display: flex;
            flex-direction: row;
            gap: 15px;
            align-items: center;
            flex-flow: row wrap;

            .input{
                width: 100px;
                padding: 5px;
            }
        }
    }

    .trigger_wrap{
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 20px;

        .bottom_text{
            display: flex;
            flex-direction: row;
            flex-flow: row wrap;
            align-items: center;
            gap: 7px;
            font-weight: 600;
            font-size: 0.9rem;
    
            .input{
                width: 100px;
                padding: 5px;
            }

        }

        .check_box_wrap{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
            font-weight: 600;
            font-size: 0.9rem;

            input, label{
                cursor: pointer;
            }
        }

        .warn_box{
            padding: 10px;
            border: 2px solid rgba(255, 204, 0, 1);
            background-color: rgba(255, 204, 0, 0.2);
            color: rgb(144, 116, 4);
            font-size: 0.9rem;
        }
    }
    .button_wrap{
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        gap: 15px;

        .cancel{
            background-color: $red;
        }
    }
    .swipeViews-panels {
        padding-top: 30px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .color_picker_section {

        .body {
            margin-top: 30px;
            // display: grid;
            // grid-template-columns: 40% 60%;

            .options {

                .subTitle {
                    color: $dark;
                    font-weight: 500;
                    margin-bottom: 20px;
                }

                .bs_section {
                    margin-bottom: 20px;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .gradient_colors_display {
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        &[data-active="false"] {
                            display: none;
                        }
                    }


                }
            }

        }
    }

    
}

#boolean_widget_picker_false {

    .section {
        .variable_List {
            display: flex;
            flex-direction: column;
            margin-bottom: 70px;

            .varibale_wrap {
                display: grid;
                grid-template-columns: 55% 35% 10%;
                flex-direction: row;
                width: 100%;
                border-radius: 5px;
                padding: 10px 20px 10px 30px;
                margin-bottom: 5px;
                box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
                align-items: center;

                .vw_color {
                    width: 60px;
                    height: 20px;
                    border-radius: 5px;
                }

                .vw_icon {
                    color: $red;
                    cursor: pointer;
                }
            }
        }

        .title {
            font-weight: 500;
            margin-bottom: 20px;
            margin-top: 0px;

            .title_desc {
                color: rgba(0, 0, 0, 0.5);
                font-size: 0.8rem;
            }
        }

        .button-wrap {
            margin-top: 30px;
        }

        .inputs-wrap {
            display: flex;
            flex-direction: column;
            gap: 40px;
        }

        .input_wrap {
            display: flex;
            flex-direction: row;
            gap: 40px;
        }

        .label_inputs_wrap {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .input_wrap {
                align-items: center;

                .title {
                    margin-bottom: 0;
                }

                .content {
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    width: 100%;
                }

                .input {
                    padding: 10px;
                }

                .colour_pick_wrap {
                    width: 100%;
                }
            }
        }

        .radiobtn_wrap {
            display: flex;
            flex-direction: column;
            grid-template-columns: auto auto auto auto;
            row-gap: 20px;
        }

        .colour_pick_wrap {
            display: grid;
            grid-template-columns: auto auto auto auto auto;
            row-gap: 20px;
            margin-bottom: 70px;

            &.no-bottom-margin {
                margin-bottom: 0;
            }

            .color_radiobtn {
                width: 40px;
                height: 40px;
                border-radius: 5px;
                cursor: pointer;
                box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
                transition: all 0.3s ease;

                &:hover {
                    transform: scale(1.05);
                }

                &:active {
                    transform: scale(0.97);
                }

                .lcp_cp_label,
                .bcp_cp_label {
                    display: block;
                    width: 100%;
                    height: 100%;
                    border-radius: 5px;
                    border: 2px solid transparent;
                    cursor: pointer;
                    transition: all 0.3s ease;

                    &[data-active="true"] {
                        border: 2px solid $theme;
                    }
                }

                .lcp_cp_radiobtn {
                    display: none;

                    &:checked~label {
                        border: 2px solid $theme;
                    }
                }

                .bcp_cp_radiobtn {
                    display: none;
                }
            }
        }

        .inputs_wrap {
            display: flex;
            flex-direction: row;
            gap: 20px;
            width: 100%;

            .input_wrap {
                display: flex;
                flex-direction: column;
                gap: 5px;
                width: 100%;

                .title {
                    margin-bottom: 0;
                }
            }
        }
    }
}
#boolean_widget_picker_fault {

    .section {
        .variable_List {
            display: flex;
            flex-direction: column;
            margin-bottom: 70px;

            .varibale_wrap {
                display: grid;
                grid-template-columns: 55% 35% 10%;
                flex-direction: row;
                width: 100%;
                border-radius: 5px;
                padding: 10px 20px 10px 30px;
                margin-bottom: 5px;
                box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
                align-items: center;

                .vw_color {
                    width: 60px;
                    height: 20px;
                    border-radius: 5px;
                }

                .vw_icon {
                    color: $red;
                    cursor: pointer;
                }
            }
        }

        .title {
            font-weight: 500;
            margin-bottom: 20px;
            margin-top: 0px;

            .title_desc {
                color: rgba(0, 0, 0, 0.5);
                font-size: 0.8rem;
            }
        }

        .button-wrap {
            margin-top: 30px;
        }

        .inputs-wrap {
            display: flex;
            flex-direction: column;
            gap: 40px;
        }

        .input_wrap {
            display: flex;
            flex-direction: row;
            gap: 40px;
        }

        .label_inputs_wrap {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .input_wrap {
                align-items: center;

                .title {
                    margin-bottom: 0;
                }

                .content {
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    width: 100%;
                }

                .input {
                    padding: 10px;
                }

                .colour_pick_wrap {
                    width: 100%;
                }
            }
        }

        .radiobtn_wrap {
            display: flex;
            flex-direction: column;
            grid-template-columns: auto auto auto auto;
            row-gap: 20px;
        }

        .colour_pick_wrap {
            display: grid;
            grid-template-columns: auto auto auto auto auto;
            row-gap: 20px;
            margin-bottom: 70px;

            &.no-bottom-margin {
                margin-bottom: 0;
            }

            .color_radiobtn {
                width: 40px;
                height: 40px;
                border-radius: 5px;
                cursor: pointer;
                box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
                transition: all 0.3s ease;

                &:hover {
                    transform: scale(1.05);
                }

                &:active {
                    transform: scale(0.97);
                }

                .lcp_cp_label,
                .bcp_cp_label {
                    display: block;
                    width: 100%;
                    height: 100%;
                    border-radius: 5px;
                    border: 2px solid transparent;
                    cursor: pointer;
                    transition: all 0.3s ease;

                    &[data-active="true"] {
                        border: 2px solid $theme;
                    }
                }

                .lcp_cp_radiobtn {
                    display: none;

                    &:checked~label {
                        border: 2px solid $theme;
                    }
                }

                .bcp_cp_radiobtn {
                    display: none;
                }
            }
        }

        .inputs_wrap {
            display: flex;
            flex-direction: row;
            gap: 20px;
            width: 100%;

            .input_wrap {
                display: flex;
                flex-direction: column;
                gap: 5px;
                width: 100%;

                .title {
                    margin-bottom: 0;
                }
            }
        }
    }
}

#boolean_widget_picker_true {

    .section {
        .variable_List {
            display: flex;
            flex-direction: column;
            margin-bottom: 70px;

            .varibale_wrap {
                display: grid;
                grid-template-columns: 55% 35% 10%;
                flex-direction: row;
                width: 100%;
                border-radius: 5px;
                padding: 10px 20px 10px 30px;
                margin-bottom: 5px;
                box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
                align-items: center;

                .vw_color {
                    width: 60px;
                    height: 20px;
                    border-radius: 5px;
                }

                .vw_icon {
                    color: $red;
                    cursor: pointer;
                }
            }
        }

        .title {
            font-weight: 500;
            margin-bottom: 20px;
            margin-top: 0px;

            .title_desc {
                color: rgba(0, 0, 0, 0.5);
                font-size: 0.8rem;
            }
        }

        .button-wrap {
            margin-top: 30px;
        }

        .inputs-wrap {
            display: flex;
            flex-direction: column;
            gap: 40px;
        }

        .input_wrap {
            display: flex;
            flex-direction: row;
            gap: 40px;
        }

        .label_inputs_wrap {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .input_wrap {
                align-items: center;

                .title {
                    margin-bottom: 0;
                }

                .content {
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    width: 100%;
                }

                .input {
                    padding: 10px;
                }

                .colour_pick_wrap {
                    width: 100%;
                }
            }
        }

        .radiobtn_wrap {
            display: flex;
            flex-direction: column;
            grid-template-columns: auto auto auto auto;
            row-gap: 20px;
        }

        .colour_pick_wrap {
            display: grid;
            grid-template-columns: auto auto auto auto auto;
            row-gap: 20px;
            margin-bottom: 70px;

            &.no-bottom-margin {
                margin-bottom: 0;
            }

            .color_radiobtn {
                width: 40px;
                height: 40px;
                border-radius: 5px;
                cursor: pointer;
                box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
                transition: all 0.3s ease;

                &:hover {
                    transform: scale(1.05);
                }

                &:active {
                    transform: scale(0.97);
                }

                .lcp_cp_label,
                .bcp_cp_label {
                    display: block;
                    width: 100%;
                    height: 100%;
                    border-radius: 5px;
                    border: 2px solid transparent;
                    cursor: pointer;
                    transition: all 0.3s ease;

                    &[data-active="true"] {
                        border: 2px solid $theme;
                    }
                }

                .lcp_cp_radiobtn {
                    display: none;

                    &:checked~label {
                        border: 2px solid $theme;
                    }
                }

                .bcp_cp_radiobtn {
                    display: none;
                }
            }
        }

        .inputs_wrap {
            display: flex;
            flex-direction: row;
            gap: 20px;
            width: 100%;

            .input_wrap {
                display: flex;
                flex-direction: column;
                gap: 5px;
                width: 100%;

                .title {
                    margin-bottom: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 770px) {
    #booleanSlide {
        .color_picker_section {
            .body {
                display: flex;
                flex-direction: column-reverse;
                justify-content: space-between;

                .options {
                    margin-top: 20px;
                }
            }
        }
    }
}