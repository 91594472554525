#cellular-sim{
    .top{
        .title{
            width: 100%;
        }
        .back_icon_wrap{
            width: max-content;
        }
    }

    .body{
        display: flex;
        flex-direction: column;
        gap: 30px;

        .card{
            background-color: $white;
            box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
            border-radius: 5px;
            padding: 20px;

            .card-title{
                font-weight: bold;
            }
        }

        .top-details-section{
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 10px;
            margin-top: 10px;

            .inputs{
                display: flex;
                gap: 20px;

                .input-wrap{
                    width: 100%;

                    .input-btn-wrap{
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        .add-icon {
                            font-size: 1.2rem;
                        }
                    }

                    .title{
                        margin-bottom: 7px;
                        font-weight: 500;
                    }
                }
            }

            .tags-wrap{
                display: flex;
                flex-direction: column;
                gap: 10px;
                padding: 10px 0 10px 0;

                .title{
                    font-weight: 500;
                }

                .tags{
                    display: flex;
                    flex-wrap: wrap;
                    gap: 10px;
                }
            }

            .buttons-wrap{
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-end;
                gap: 20px;
            }
        }

        .sim-details{
            display: flex;
            flex-direction: column;
            gap: 10px;

            .section{
                width: 100%;

                &.details-section {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    padding: 10px 10px 10px 10px;
                    background-color: $light1;
                    gap: 20px;
                    width: 100%;
                    max-width: 1400px;
                    
                    .inner-section{
                        &:nth-child(1) {
                            display: flex;
                            flex-wrap: wrap;
                            gap: 20px;
                            justify-content: space-between;
                            align-items: center;
                            max-width: 800px;
                            width: 100%;

                            .status{
                                font-weight: 600;
                                padding: 5px 10px 5px 10px;
                                background-color: $secondary;
                                color: $white;

                                &[data-color="grey"] {background-color: $secondary}
                                &[data-color="yellow"] {background-color: $yellow}
                                &[data-color="blue"] {background-color: $blue}
                                &[data-color="green"] {background-color: $green}
                                &[data-color="orange"] {background-color: $orange}
                                &[data-color="red"] {background-color: $red}
                            }

                            .detail-wrap{
                                font-size: 0.8rem;

                                .title{
                                    font-weight: 500;
                                    text-transform: uppercase;
                                }
                                
                                .detail{
                                    word-break: break-all;
                                }
                            }
                        }

                        .button{
                            display: flex;
                            align-items: center;
                            gap: 5px;
                        }
                    }
                }
            }
        }

        .sim-overview{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 10px;
            width: 100%;

            .row{
                display: flex;
                flex-wrap: wrap;
                gap: 20px;
                width: 100%;
                
                .row-item{
                    max-width: 350px;
                    width: 100%;

                    .title{
                        color: $secondary;
                    }

                    .value{
                        font-size: 1.1rem;
                    }
                }
            }
        }

        .sim-location{
            min-height: 400px;
            width: 100%;
            max-width: 400px;
            
            .sim-location-map{
                padding: 20px 0 20px 0;
                width: 100%;
                max-width: 400px;
            }
        }

        .cards-double-wrap{
            display: flex;
            width: 100%;
            gap: 20px;
        }
    }
}

@media screen and (max-width: 960px) {
    #cellular-sim{
        .body{
            .top-details-section{
                .inputs{
                    flex-wrap: wrap;
                }
            }

            .cards-double-wrap{
                flex-wrap: wrap;
            }

            .sim-details{
                .section{
                    &.details-section {
                        flex-direction: column;
                        align-items: flex-start;
                        
                        .inner-section{
                            &:nth-child(1) {
                                flex-direction: column;
                                align-items: flex-start;
                            }
                        }
                    }
                }
            }

            .sim-location{
                max-width: 100%;
                
                .sim-location-map{
                    max-width: 100%;
                }
            }
        }
    }
}