.backdrop{
    position: absolute;
    z-index: 99;

    .backDrop_content{
        display: flex;
        flex-direction: column;
        min-height: 110vh;
        padding-left: 300px;
        gap: 20px;
    }
}

@media screen and (max-width: 770px) {
    .backdrop{
        .backDrop_content{
            padding-left: 0px;
        }
    }
}

@media screen and (min-width: 1023px) and (max-width: 1080px) {
    .backdrop{
        .backDrop_content{
            padding-left: 300px;
        }
    }
}