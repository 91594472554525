#addNewWorkSpace{
    display: flex;
    flex-direction: column;

    .title{
        font-weight: 500;
    }

    .input{
        padding: 10px;
        margin-top: 7px;
        margin-bottom: 20px;
    }

    .button{
        align-self: flex-end;
    }
}