.devices_container {
  margin-top: 30px;
  padding: 5px 0px;
  // max-height: 243px;
  // overflow-y: auto;

  .devices_list_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 5px;

    .device_top_title {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .devices_list {
    max-height: 100%;
    overflow-y: auto;
  }

  .devices_list li {
    list-style-type: none;
    font-size: 0.9rem;
  }

  .page_devices_btn {
    width: 100%;
    padding: 6px 10px;
    text-align: left;
    padding-left: 20px;
    font-size: 1rem;
    background: linear-gradient(rgba(69, 71, 75, 0.2) 0%, rgba(69, 71, 75, 0.1) 48.44%, rgba(69, 71, 75, 0.2) 100%);

    border: none;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.09);
    }
  }

  .add_devices_button {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    font-weight: 700;
    padding-left: 9px;
    width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
    // background-color: rgba(0, 0, 0, 0.04);
    background: transparent;
    border: none;
    cursor: pointer;

    &:hover {
      // background-color: rgba(0, 0, 0, 0.02);
      // border-radius: 12px;
      background: transparent;
    }
  }

  .collapsed {
    display: none; /* or any other styles to hide the content */
  }

  .expanded {
    display: block; /* or any other styles to show the content */
  }

  .collapsible {
    background: "rgb(182, 187, 196, 0.8)";
  }

  .collapsible .icon_device {
    font-size: 1rem;
  }
  .collapsible .icon {
    float: right;
    margin-left: 5px;
    font-size: 1rem;
  }

  // .collapsible .icon:before {
  //   content: '+';
  // }

  // .collapsible.active .icon:before {
  //   content: '−';
  // }

  .middle_title {
    padding-left: 10px;
    font-weight: 500;
    font-size: 0.9rem;
  }

  .middle_title_btn {
    padding-left: 10px;
    font-weight: 500;
    font-size: 0.9rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .middle_title_none_list {
    padding: 10px 5px 10px 15px;
    font-weight: 500;
    font-size: 0.9rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .page_list {
    max-height: 100%;
    overflow-y: auto;

    .page_list_top {
      display: flex;
      align-items: center;
      padding-left: 10px;
      justify-content: space-between;
      border-bottom: 1px solid rgba(225, 225, 225, 1);

      .pages_top_title {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        .wrap_btn {
          display: flex;
          justify-content: space-between;
          padding-left: 14px;
          align-items: center;
          width: 100%;
          padding-right: 10px;
        }

        .middle_title {
          font-size: 0.8rem;
        }
      }
    }
    .page_list_top_none_list {
      display: flex;
      align-items: center;
      padding-left: 17px;
      justify-content: space-between;
      border-bottom: 1px solid rgba(225, 225, 225, 1);

      .pages_top_title {
        display: flex;
        justify-content: center;
        align-items: center;

        .middle_title {
          font-size: 0.8rem;
        }
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.09);
      }
    }

    .active {
      background-color: rgba(61, 61, 61, 0.4);
    }
  }

  .page_list .page_list_map {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
  }

  .editSection .edit_button {
    padding-right: 10px;
    color: blue;
    cursor: pointer;
  }

  .editSection .delete_button {
    padding-right: 10px;
    color: red;
    cursor: pointer;
  }

  .page_list_map .editSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .page_list li {
    list-style-type: none;
    font-size: 0.9rem;
  }

  .page_list_btn {
    width: 100%;
    padding: 6px 10px;
    text-align: left;
    font-size: 0.7rem;
    padding-left: 38px;
    background-color: transparent;
    border: none;
    cursor: pointer;

    &:active {
      background-color: rgba(0, 0, 0, 0.09);
    }
  }

  .add_page_button {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    font-weight: 700;
    width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
    background-color: transparent;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: transparent;
    }
  }
}

@media screen and (max-width: 400px) {
  .devices_container {
    .page_list {

      .page_list_top {
        .pages_top_title {
          .wrap_btn {
            padding-left: 24px;
          }
        }
      }

      .page_list_top_none_list {
        padding-left: 30px;
      }
    }

    .page_list_btn {

      padding-left: 60px;
     
      &:active {
        background-color: rgba(0, 0, 0, 0.09);
      }
    }
  }
}
