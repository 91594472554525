#global-dashboard {
    .buttons_wrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .top_buttons {
            display: flex;
            flex-direction: row;
            flex-flow: row wrap;
            width: 80%;
            justify-content: flex-end;
            align-items: center;
            transition: all 0.3s ease;
            gap: 5px;

            .top_button_wrap {
                &[data-active="false"] {
                    display: none;
                }
            }

            .top_button {
                padding: 10px 15px 10px 15px;
                outline: none;
                border: none;
                background-color: $theme;
                color: $white;
                font-weight: 600;
                box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
                border-radius: 5px;
                cursor: pointer;
                transition: all 0.2s ease;
                display: flex;
                gap: 4px;
                align-items: center;

                &:active {
                    transform: scale(0.97);
                }

                &[data-active="true"] {
                    display: flex;
                }

                &[data-active="false"] {
                    display: none;
                }


            }
        }

        .select_list {
            margin-right: 200px;
            width: 100%;
        }
        #nav_top_menu_icon{
            display: none;
        }
        
    }

    // .dashboard-pages-dropdown {
    //     justify-content: space-between;
    //     display: flex;
    //     flex-direction: row;
    // }
}

@media screen and (max-width: 770px) {
    #global-dashboard {
        .buttons_wrap {
            display: block;
            .select_list-menu_icon {
                margin-bottom: 20px;
                gap: 10px;
                display: flex;
                justify-content: space-between;
                .select_list {
                    margin-right: 0;
                    width: 90%;
                }
            }
            
            .top_buttons {
                width: 100%;
                justify-content: flex-end;
            }
            #nav_top_menu_icon{
                margin: 10px;
                display: flex;
                font-size: 30px;
                cursor: pointer;
            }
        }
    }
}
// @media screen and (max-width: 400px) {
//     #global-dashboard {
//         .buttons_wrap {
//             .top_buttons {
//                 display: none;
//                 justify-content: flex-end;
//                 border: 1px solid black;
//             }
//         }
//     }
// }