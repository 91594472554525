#navbar {
    color: $light;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    .nav_top {
        background-color: $theme;
        width: 100vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 0px 0 30px;
        top: 0;
        left: 0;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px;
        height: 4.75rem;
        z-index: 999;
        transition: background-color 0.3s ease;

        .nav_top_grid_item {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            margin-right: 50px;

            .nav_top_menu_icon_wrap {
                display: flex;
            }

            #nav_top_menu_icon {
                font-size: 25px;
                cursor: pointer;
            }

            span {
                font-size: 1.4rem;
                font-weight: 100;
                margin: 0 20px 0 20px;
            }

            .nav_top_title {
                max-width: 400px;
                max-height: 3.75rem;
            }

            .nav_profile_image {
                cursor: pointer;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                border: 1px solid $dark;
            }

            
            #nav_profile_wrapper {
                background-color: $light;
                display: flex;
                flex-direction: column;
                box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
                border-radius: 0.25rem;
                min-width: 2rem;
                max-width: 15rem;
                text-align: center;
                top: 0;
                right: 0;
                margin-top: 4.75rem;
                margin-right: 25px;

                &[data-active="true"] {
                    display: flex;
                    
                }

                &[data-active="false"] {
                    display: none;
                }


                .nav_profile_item {
                    color: $dark;
                    text-decoration: none;
                    padding: 15px 10px 15px 10px;
                    cursor: pointer;
                    
                    
                    

                    &:hover {
                        background-color: rgba(0, 0, 0, 0.04);
                    }

                    &:nth-child(2) {
                        border-top: 1px solid rgba(0, 0, 0, 0.14);
                        color: $red;
                    }
                }

            
            }
        }
    }

    .image_wrapper{
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    .nav_body_main {

        position: relative;

        .nav_slider_button{
            position: absolute;
            font-size: 2rem;
            top:90vh;
            right:-3.8rem;
            height: 40px;
            width: 40px;
            border-radius: 20px;
            border: none;
            background-color: rgba(194, 225, 255, 0.793);
            color:#004fcfd0;
            z-index: 100099;
    
        }

        .nav_slider_button:hover {
            background-color: rgba(110, 183, 255, 0.793);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); 
            cursor: pointer;
        }

        .nav_slider_button:active {
            transform: scale(0.9); 
        } 

        .nav_body {
            margin-top: 0rem;
            display: flex;
            flex-direction: row;

            .nav_slider {
                height: 100vh;
                background-color: $light1;
                color: $dark;
                z-index: 100;
                overflow-x: hidden;
                overflow-y: auto;
                display: flex;
                flex-direction: column;
                transition: width 0.4s ease;
                position: relative;
                // padding-bottom: 12vh;

                .nav_slider_in_wrap {
                    width: 100%;
                    height: auto;
                    background-color: $light1;
                    color: $dark;
                    z-index: 100;
                    overflow-x: hidden;
                    padding-top: 0px;
                    display: flex;
                    flex-direction: column;
                    transition: all 0.4s ease;
                    position: relative;
                    max-width: 270px;
                }

                .nav_dashboards_wrap {
                    padding-left: 0px;
                    padding-right: 20px;
                    margin-bottom: 22px;

                    

                    .title {
                        margin-bottom: 10px;
                        margin-top:20px;
                        margin-left: 10px;
                    }

                    .nav_dashboards_wrapper {
                        display: flex;
                        flex-direction: row;
                        gap: 10px;
                        align-items: center;
                        margin-left:10px;

                        .nav_dashboard_logo {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                        }
                    }
                }

                .nav_slider_link_logout{
                    display: flex;
                    flex-direction: row;
                    text-decoration: none;
                    align-items: center;
                    justify-content: flex-start;
                    width: 100%;
                    padding: 15px 10px 15px 20px;
                    align-items: center;
                    font-size: 0.9rem;
                    transition: all 0.1s ease-in;
                    overflow: hidden;
                    min-height: 50px;
                    border: none;
                    background-color: transparent;
                    font-family: Arial, Helvetica, sans-serif;
                    color: $dark;
                    margin-top: 30px;
                    cursor: pointer;

                    .icon {
                        pointer-events: none;
                        font-size: 1rem;
                    }

                    span {
                        pointer-events: none;
                        width: max-content;
                        font-size: 0.9rem;
                        margin-left: 30px;
                        position: absolute;
                    }

                    &[data-active="true"] {
                        background-color: rgba(0, 0, 0, 0.08);
                        color: $theme;
                    }

                    &[data-active="false"] {
                        background-color: transparent;
                        color: $dark;
                    }

                    &:hover {
                        background-color: rgba(0, 0, 0, 0.04);
                    }

                    &[data-disabled="true"] {
                        background-color: rgba(0, 0, 0, 0.15);
                        color: rgba(0, 0, 0, 0.4);
                        pointer-events: none;
                    }
                }

                .nav_slider_link {
                    display: flex;
                    flex-direction: row;
                    text-decoration: none;
                    align-items: center;
                    justify-content: flex-start;
                    width: 100%;
                    padding: 15px 10px 15px 20px;
                    align-items: center;
                    font-size: 0.9rem;
                    transition: all 0.1s ease-in;
                    overflow: hidden;
                    min-height: 50px;
                    border-bottom: 1px solid rgb(168, 168, 168);

                    .icon {
                        pointer-events: none;
                    }

                    span {
                        pointer-events: none;
                        width: max-content;
                        font-size: 0.9rem;
                        margin-left: 30px;
                        position: absolute;
                    }

                    &[data-active="true"] {
                        background-color: rgba(0, 0, 0, 0.08);
                        color: $theme;
                    }

                    &[data-active="false"] {
                        background-color: transparent;
                        color: $dark;
                    }

                    &:hover {
                        background-color: rgba(0, 0, 0, 0.04);
                    }

                    &[data-disabled="true"] {
                        background-color: rgba(0, 0, 0, 0.15);
                        color: rgba(0, 0, 0, 0.4);
                        pointer-events: none;
                    }

                }

                &[data-active="true"] {
                    width: 270px;
                }

                &[data-active="false"] {
                    width: 70px;

                    .nav_slider_link {
                        span {
                            display: none;
                        }

                        &[data-active="true"] {
                            border-left: 5px solid $theme;
                            background-color: rgba(0, 0, 0, 0.08);
                            color: $theme;
                        }
                    }
                }
            }

            .nav_containers {
                width: 100%;
                transition: all 0.5s ease;
                overflow-x: hidden;
            }
        }
    }

}

@media screen and (max-width: 770px) {
    #navbar {
        .nav_top {
            .nav_top_grid_item {

                .nav_top_menu_icon_wrap {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }

                .nav_top_title {
                    max-width: 120px;
                    //display: none;
                }
            }
        }

        .nav_body {

            .nav_slider {
                position: fixed;

                &[data-active="false"] {
                    width: 0;

                    .nav_slider_link {
                        span {
                            display: block;
                        }

                        &[data-active="true"] {
                            border-left: 0px solid $theme;
                            background-color: rgba(0, 0, 0, 0.08);
                            color: $theme;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) and (max-width: 1080px) {
    #navbar {
        .nav_top {
            .nav_top_grid_item {
                .nav_top_menu_icon_wrap {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }
            }
        }

        .nav_body {
            .nav_slider {
                position: fixed;

                .nav_slider_in_wrap {
                    position: fixed;
                }

                .nav_slider_link {
                    span {
                        position: absolute;
                        margin-left: 40px;
                    }
                }

                &[data-active="false"] {
                    width: 0;

                    .nav_slider_link {
                        span {
                            display: block;
                        }

                        &[data-active="true"] {
                            border-left: 0px solid $theme;
                            background-color: rgba(0, 0, 0, 0.08);
                            color: $theme;
                        }
                    }
                }
            }

            .nav_containers {
                height: 100%;
            }
        }
    }
}