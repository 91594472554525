#notebook{

    .button-wrap{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-bottom: 30px;
    }

    .notes-wrap{
        width: 100%;
        display: grid;
        grid-template-columns: 32% 32% 32%;
        gap: 30px;

        &[data-active="false"] {
            display: none;
        }

        .note-wrap{
            width: 100%;
            background-color: #ffc10760;
            max-width: 400px;
            border-radius: 7px;
            
            .padding{
                padding: 7px 15px 7px 15px;
            }
            
            .title{
                border-top-left-radius: 7px;
                border-top-right-radius: 7px;
                background-color: $yellow;
                text-transform: uppercase;
                word-break: break-all;
            }
            
            .desc{
                word-break: break-all;
            }
            
            .files{
                display: flex;
                flex-flow: row wrap;
                gap: 10px;
                margin-top: 10px;
                background-color: rgba(0, 0, 0, 0.1);
                word-break: break-all;

                &[data-active="false"] {
                    display: none;
                }
            }

            .note-bottom{
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding: 15px 20px 15px 20px;
                gap: 20px;

                &[data-active="false"] {
                    display: none;
                }

                .icon{
                    color: $blue;
                    cursor: pointer;
                    font-size: 1.4rem;

                    &[data-active="false"]{
                        display: none;
                    }

                    &.delete{
                        color: $red;
                    }
                }
            }

            .author-wrap{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 5px;

                .author, .time{
                    font-size: 0.8rem;
                }

                .time {
                    font-style: italic;
                    color: $secondary;
                }
            }
        }
    }
}

@media screen and (max-width: 960px) {
    #notebook{
        .notes-wrap{
            display: flex;
            flex-direction: column;
        }
    }
}