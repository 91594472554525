// #home_content {
//   padding: 0px 0px 5px 0px;
//   height: 100vh;

//   .home_header {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     padding: 0px 10px 0px 10px;
//     border-bottom: 1px solid rgba(217, 217, 217, 1);

//     .left_of_home {
//       display: flex;
//       align-items: center;

//       .home_logo {
//         width: 130px;
//       }

//       .home_nav_list {
//         margin-left: 20px;

//         ul {
//           display: flex;
//           list-style: none;

//           li {
//             display: flex;
//             justify-content: center;
//             font-size: 0.8rem;
//             font-weight: 500;
//             padding: 3px 10px;
//             border-radius: 5px;
//             margin-right: 7px;
//           }

//           .nav-button:hover {
//             background: linear-gradient(to right, #c987e3, #50c5f8);
//             color: white;
//             border-radius: 5px;
//             transition: 0.1s ease;

//             cursor: pointer;
//           }

//           .nav-button.active {
//             background: linear-gradient(to right, #c987e3, #50c5f8);
//             box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
//               rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
//             color: white;
//             border-radius: 5px;
//             transition: all 0.3s ease;
//             transform: scale(1.05);
//             cursor: pointer;
//           }
//         }
//       }
//     }

//     .right_of_home {
//       display: flex;
//       align-items: center;
//       font-size: 2.3rem;
//       margin-right: 20px;
//       position: relative;

//       .select_dropdown {
//         width: 200px; /* Adjust the width as needed */
//         padding: 8px 10px;
//         font-size: 0.8rem;
//         border: 1px solid #ccc;
//         border-radius: 5px;
//       }

//       .option_dropdown {
//         font-size: 0.8rem;
//       }

//       //   .select_dropdown {
//       //     width: 200px;
//       //     border-radius: 8px;
//       //     margin-left: 20px;
//       //     height: 30px;
//       //     background-color: #eae5e5;
//       //     box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 6px -2px,
//       //           rgba(0, 0, 0, 0.3) 0px 1px 3px -3px;
//       //     padding: 5px 10px 5px 5px;
//       //     border: none;

//       //     option{
//       //         padding: 5px 10px 5px 5px;
//       //         border: none;
//       //     }
//       //   }

//       .nav_body {
//         margin-top: 0rem;
//         display: flex;
//         flex-direction: row;
//         margin-right: -3rem;
//         margin-left: 1rem;

//         .nav_slider {
//           height: 27.2vh;
//           background-color: rgba(248, 249, 250, 1);
//           color: $dark;
//           z-index: 100;
//           overflow-x: hidden;
//           overflow-y: auto;
//           display: flex;
//           flex-direction: column;
//           transition: width 0.4s ease;
//           position: relative;
//           right: 0.5rem;
//           border-radius: 0.25rem;
//           // padding-bottom: 12vh;

//           .nav_slider_in_wrap {
//             width: 100%;
//             height: auto;
//             background-color: rgba(248, 249, 250, 1);
//             color: $dark;
//             z-index: 100;
//             overflow-x: hidden;
//             padding-top: 0px;
//             display: flex;
//             flex-direction: column;
//             transition: all 0.4s ease;
//             position: relative;
//             max-width: 270px;
//           }

//           .nav_dashboards_wrap {
//             padding-left: 0px;
//             padding-right: 20px;
//             margin-bottom: 22px;

//             .title {
//               margin-bottom: 10px;
//               margin-top: 20px;
//               margin-left: 10px;
//             }

//             .nav_dashboards_wrapper {
//               display: flex;
//               flex-direction: row;
//               gap: 10px;
//               align-items: center;
//               margin-left: 10px;

//               .nav_dashboard_logo {
//                 width: 45px;
//                 height: 45px;
//                 border-radius: 50%;
//               }
//             }
//           }

//           .nav_slider_link_logout {
//             display: flex;
//             flex-direction: row;
//             text-decoration: none;
//             align-items: center;
//             justify-content: flex-start;
//             width: 100%;
//             padding: 15px 10px 15px 20px;
//             align-items: center;
//             font-size: 0.9rem;
//             transition: all 0.1s ease-in;
//             overflow: hidden;
//             min-height: 50px;
//             border: none;
//             background-color: transparent;
//             font-family: "Poppins", sans-serif;
//             color: $dark;
//             margin-top: 30px;
//             cursor: pointer;

//             .icon {
//               pointer-events: none;
//               font-size: 1rem;
//             }

//             span {
//               pointer-events: none;
//               width: max-content;
//               font-size: 0.9rem;
//               margin-left: 30px;
//               position: absolute;
//             }

//             &[data-active="true"] {
//               background-color: rgba(0, 0, 0, 0.08);
//               color: $theme;
//             }

//             &[data-active="false"] {
//               background-color: transparent;
//               color: $dark;
//             }

//             &:hover {
//               background-color: rgba(0, 0, 0, 0.04);
//             }

//             &[data-disabled="true"] {
//               background-color: rgba(0, 0, 0, 0.15);
//               color: rgba(0, 0, 0, 0.4);
//               pointer-events: none;
//             }
//           }

//           .nav_slider_link {
//             display: flex;
//             flex-direction: row;
//             text-decoration: none;
//             align-items: center;
//             justify-content: flex-start;
//             width: 100%;
//             padding: 15px 10px 15px 20px;
//             align-items: center;
//             font-size: 0.9rem;
//             transition: all 0.1s ease-in;
//             overflow: hidden;
//             min-height: 50px;
//             border-bottom: 1px solid rgb(209, 209, 209);

//             .icon {
//               pointer-events: none;
//             }

//             span {
//               pointer-events: none;
//               width: max-content;
//               font-size: 0.9rem;
//               margin-left: 30px;
//               position: absolute;
//             }

//             &[data-active="true"] {
//               background-color: rgba(0, 0, 0, 0.08);
//               color: $theme;
//             }

//             &[data-active="false"] {
//               background-color: transparent;
//               color: $dark;
//             }

//             &:hover {
//               background-color: rgba(0, 0, 0, 0.04);
//             }

//             &[data-disabled="true"] {
//               background-color: rgba(0, 0, 0, 0.15);
//               color: rgba(0, 0, 0, 0.4);
//               pointer-events: none;
//             }
//           }

//           &[data-active="true"] {
//             width: 171px;
//             top: -0.9rem;
//           }

//           &[data-active="false"] {
//             width: 70px;

//             .nav_slider_link {
//               span {
//                 display: none;
//               }

//               &[data-active="true"] {
//                 border-left: 5px solid $theme;
//                 background-color: rgba(0, 0, 0, 0.08);
//                 color: $theme;
//               }
//             }
//           }
//         }

//         .nav_top_grid_item {
//           display: flex;
//           flex-direction: row-reverse;
//           align-items: center;
//           margin-right: 50px;

//           #nav_profile_image {
//             position: relative;
//             cursor: pointer;
//             width: 35px;
//             height: 35px;
//             border-radius: 50%;
//             border: 1px solid $dark;
//           }

//           #nav_profile_wrapper {
//             position: absolute;
//             top: 100%;
//             right: 0.3rem;
//             width: 7rem;
//             background-color: $light;
//             display: flex;
//             flex-direction: column;
//             box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
//             border-radius: 0.25rem;
//             min-width: 2rem;
//             max-width: 15rem;
//             text-align: center;
//             font-size: 1.1rem;
//             z-index: 12;
//             display: none;

//             &[data-active="true"] {
//               display: flex;
//               right: 2.5rem;
//             }

//             &[data-active="false"] {
//               display: none;
//             }

//             .nav_profile_item {
//               color: $dark;
//               text-decoration: none;
//               padding: 7px 14px;
//               text-align: left;
//               font-size: 1rem;
//               cursor: pointer;
//               border: none;

//               &:hover {
//                 background-color: rgba(0, 0, 0, 0.04);
//               }

//               &:nth-child(3) {
//                 border-top: 1px solid rgba(0, 0, 0, 0.14);
//                 color: $red;
//               }
//             }
//           }
//         }

//         .nav_top_grid_item_right {
//           display: flex;
//           flex-direction: row-reverse;
//           align-items: center;
//           margin-right: 36px;

//           #nav_side_panel_button {
//             position: relative;
//             cursor: pointer;
//             top: 0.4rem;
//           }

//           #nav_profile_wrapper {
//             position: absolute;
//             top: 100%;
//             right: 3 0.3rem;
//             width: 7rem;
//             background-color: $light;
//             display: flex;
//             flex-direction: column;
//             box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
//             border-radius: 0.25rem;
//             min-width: 2rem;
//             max-width: 15rem;
//             text-align: center;
//             font-size: 1.1rem;
//             z-index: 12;
//             display: none;

//             &[data-active="true"] {
//               display: flex;
//               width: 9rem;
//               border-radius: 5px;
//               overflow: hidden;
//               z-index: 9999999;
//             }

//             &[data-active="false"] {
//               display: none;
//             }

//             .nav_profile_item {
//               color: $dark;
//               text-decoration: none;
//               padding: 7px 14px;
//               text-align: left;
//               font-size: 1rem;
//               cursor: pointer;
//               border: none;
//               background-color: transparent;

//               &:hover {
//                 background-color: rgba(0, 0, 0, 0.04);
//               }

//               // &:nth-child(3) {
//               //   border-top: 1px solid rgba(0, 0, 0, 0.14);
//               //   color: $red;
//               // }
//             }
//           }
//         }

//         .nav_containers {
//           width: 100%;
//           transition: all 0.5s ease;
//           overflow-x: hidden;
//         }
//       }

//       .nav_side_panel_container {
//         position: relative;
//         top: 0.3rem;
//         right: -0.9rem;

//         .nav_side_panel {
//           position: absolute;
//           top: 4rem;
//           right: 2rem;
//           width: 224px;
//           right: -55px;
//         }
//       }
//     }
//   }
// }

#home_content {
  padding: 0px;
  height: 100vh;

  .home_main {
    display: flex;


    .home_left_side {
      width: 180px;
      height: 100vh;
      border-right: 1px solid rgb(41, 41, 41, 0.4);
      // border: 1px solid rgba(39, 40, 41, 0.1);
      position: absolute;
      transition: 0.5s;
      flex-direction: column;

      // Logo

      .home_logo_container {
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid rgb(39, 40, 41, 0.1);
        box-shadow: -1px 2px 10px -6px rgba(0,0,0,0.75);

        .home_logo {
          // width: 130px;
          max-height: 37px;
          max-width: 130px;
        }
      }

      .toggleIcons {
        color: transparent;
      }

      // Middle Panel of Left Side Panel

      .home_left_middle {
        height: calc(100% - 237px);
        overflow: auto;
        scrollbar-width: none; 
        -ms-overflow-style: none;  
      }

      .home_left_middle::-webkit-scrollbar{
        display: none; 
      }

      // Buttons Section of Left Side Panel

      .home_left_buttons {
        background-color: rgba(249, 249, 249, 1);
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;

        .button_container {
          width: 100%;
          border-bottom: 1px solid rgb(41, 41, 41, 0.5);

          .left_button {
            width: 100%;
            padding: 6px 17px;
            text-align: left;
            background: linear-gradient(180deg,
                rgba(217, 217, 217, 0.97) 0%,
                rgba(217, 217, 217, 0) 48.44%,
                #d9d9d9 100%);
            border: none;
            font-size: 0.7rem;
            font-weight: 700;

            .nav_slider_link{
              text-decoration: none;
              color: black;
            }
          }

          .left_button:hover {
            background: linear-gradient(180deg,
                rgba(115, 118, 118, 0.97) 0%,
                rgba(115, 118, 118, 0.69) 56.25%,
                #737676 100%);
            color: #fff;
          }

          .left_button.active {
            background: linear-gradient(180deg,
                rgba(115, 118, 118, 0.97) 0%,
                rgba(115, 118, 118, 0.69) 56.25%,
                #737676 100%);
            color: #fff;
          }
        }
      }
    }

    .nav_open{
      left: 0;
    }

    .nav_closed{
      left: -180px;
    }

    .home_right_side {
      width: calc(100% - 180px);
      height: 100vh;
      margin-left: 180px;
      overflow-x: auto;
      transition: 0.5s;
    }

    .home_right_side.full_width {
      width: 100%; 
      margin-left: 0; 
    }
  }
}

@media screen and (max-width: 725px) {
  #home_content {
    height: 93vh;

    .home_main {
      display: flex;
      flex-direction: column;

      .home_left_side {
        width: 100%;
        height: 3rem;
        z-index: 1000;
        position: relative;
        overflow: hidden;

        .home_logo_container {
          display: flex;
          justify-content: space-between;
          padding-left: 10px;
          padding-right: 10px;

          .toggleIcons {
            color: black;
            font-size: 2.5rem;
            z-index: 1000;
          }
        }

        .home_left_middle {
          display: none;
        }

        .home_left_buttons {
          display: none;
        }
      }

      .home_right_side {
        width: 100%;
        margin-left: 0px;
        // margin-top: 3rem;
      }


      .mobile_window {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        border: 1px solid #ccc;
        // padding: 10px;
        transition: transform 0.3s ease;
        z-index: 999;

        .middle_title {
          padding-left: 10px;
          font-weight: 500;
          font-size: 1.1rem;
        }

        .page_devices_btn {
          width: 100%;
          padding: 8px 10px;
          text-align: left;
          padding-left: 27px;
          font-size: 0.9rem;
          background-color: transparent;
          border: none;
          cursor: pointer;
        }

        .add_devices_button {
          display: flex;
          align-items: center;
          font-size: 1.1rem;
          font-weight: 600;
          padding-left: 20px;
          width: 100%;
          padding-top: 6px;
          padding-bottom: 6px;
          border: none;
          cursor: pointer;
        }

        .pages_container .add_page_button {
          display: flex;
          align-items: center;
          font-size: 1rem;
          font-weight: 600;
          padding-left: 20px;
          width: 100%;
          padding-top: 6px;
          padding-bottom: 6px;
          background-color: rgba(0, 0, 0, 0.04);
          border: none;
          cursor: pointer;
        }

        .pages_container .page_list_btn {
          width: 100%;
          padding: 8px 10px;
          text-align: left;
          font-size: 0.9rem;
          padding-left: 46px;
          background-color: transparent;
          border: none;
          cursor: pointer;
        }

        .workspaces_container .page_workspaces_btn {
          width: 100%;
          padding: 8px 10px;
          text-align: left;
          font-size: 0.9rem;
          padding-left: 46px;
          background-color: transparent;
          border: none;
          cursor: pointer;
        }

        .workspaces_container .add_workspaces_button {
          display: flex;
          align-items: center;
          font-size: 1rem;
          font-weight: 600;
          padding-left: 20px;
          width: 100%;
          padding-top: 6px;
          padding-bottom: 6px;
          background-color: rgba(0, 0, 0, 0.04);
          border: none;
          cursor: pointer;
        }


      }

      .mobile_button_container {
        width: 100%;
      }

      .home_mobile_left_buttons {
        position: absolute;
        bottom: 0;
        width: 100%;
        background-color: white;
      }

      .mobile_left_button {
        width: 100%;
        padding: 6px;
        background: linear-gradient(180deg,
            rgba(217, 217, 217, 0.97) 0%,
            rgba(217, 217, 217, 0) 48.44%,
            #d9d9d9 100%);
        border: none;
        font-size: 1rem;
        font-weight: 700;

        .nav_slider_link{
          text-decoration: none;
          color: black;
        }
      }

      .mobile_left_button:hover {
        background: linear-gradient(180deg,
            rgba(115, 118, 118, 0.97) 0%,
            rgba(115, 118, 118, 0.69) 56.25%,
            #737676 100%);
      }

      .home_mobile_left_middle {
        margin-top: 80px;
        height: calc(100% - 293px);
        overflow-y: scroll;

      }

      .mobile_window.hidden {
        transform: translateX(-100%);
      }
    }
  }
}