#acceptWorkspace{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    text-align: center;
    padding-top: 70px;

    .title{
        text-transform: uppercase;
    }

    .liner_progress{
        width: 250px;
    }
}